import { useState } from 'react'
import {Tabs, Tab } from 'react-bootstrap'
import SendKarma from './karma-send'

export default function KarmaPage() {

  const [key, setKey] = useState<string>('send_karma');

  const karmaTabSelected = (tabSelected: string) => {
    setKey(tabSelected)
  }

  return (
    <div>
      <h3 className="text-left ml-1">Karma</h3>
      <Tabs 
        className="font-weight-bold" 
        activeKey={key}
        onSelect={(k) => {if(k) {karmaTabSelected(k)}}}
        fill
      >
        <Tab eventKey="send_karma" title="Send Karma"><SendKarma /></Tab>
      </Tabs>
    </div>
  )
}