import { useState } from 'react'
import {Tabs, Tab } from 'react-bootstrap'
import AddItem from './add-item'
import AddProject from './add-project'
import AddPost from './add-post'

export default function Add() {

  const [key, setKey] = useState<string>('add_post');

  const communityTabSelected = (tabSelected: string) => {
    setKey(tabSelected)
  }

  const doNothing = () => {}

  return (
    <div>
      <h3 className="text-left ml-1">Add</h3>
      <Tabs 
        className="font-weight-bold" 
        activeKey={key}
        onSelect={(k) => {if(k) {communityTabSelected(k)}}}
        fill
      >
        <Tab eventKey="add_post" title="Add Post"><AddPost newPost={true} projectId={0} modal={false} closeModal={doNothing}/></Tab>
        <Tab eventKey="add_project" title="Add Project"><AddProject newProject={true} /></Tab>
        <Tab eventKey="add_item" title="Add Item"><AddItem newItem={true} /></Tab>
      </Tabs>
    </div>
  )
}