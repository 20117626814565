import React, {useState, useEffect, useContext} from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'

import { UserContext } from '../contexts/userContext';
import ProjectsCardCollection from './collection-projects'
import { getUserProjects } from '../api/backend-api'
import { ProjectList } from '../interfaces/common-types'
import {SearchProjects} from '../utils/search'

export default function UserProjects({
  userId,
  searchText
}: {
  userId: number, 
  searchText: string
}) {
  const [projects, setProjects] = useState<ProjectList>()
  const {userProfile} = useContext(UserContext)

  useEffect(() => {
    getUserProjects(userId)
    .then((response) => {
      const formattedJsonResponse: ProjectList = { projects: response.data }
      // console.log(formattedJsonResponse)
      setProjects(formattedJsonResponse)
    })
    .catch((error) => console.log(error))
  }, [])
  
  const myProfile = () => {
    return (userProfile.id === userId)
  }

  if(!projects)
  {
    return(
      <div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      )
  }

  return(
    <div>
      <ProjectsCardCollection 
        projects={SearchProjects(projects, searchText)}
        searchText={searchText}
      />
      {myProfile() ?
        <NavLink 
          to='/add_project'
          className='navbar-additem-inactive'
        >
          <div className="icon"><FontAwesomeIcon icon={faCirclePlus} /></div>
          <div className="name">Add Project</div>
        </NavLink>
        :
        <></>
      }
    </div>
  )
}