import {ItemList, Item, ProjectList, Project, AllPostType, AllPostTypeList, ProjectPost, ProjectPostList, Post} from '../interfaces/common-types'

export function SearchItems(
  items: ItemList,
  searchText: string
): ItemList {
  // Find if search text exists in the name of any of the items.
  const setFilteredItems: ItemList = { items: items.items.filter((item: Item) => ( item.name.toLowerCase().includes(searchText) )) }

  // return the filters items.
  if(setFilteredItems)
  {
    console.log("Filtered Items", setFilteredItems)
    return setFilteredItems
  }
  else
  {
    console.log("Items: no filter", items)
    return items
  }
}

export function SearchProjects(
  projects: ProjectList,
  searchText: string
): ProjectList {
  // Find if search text exists in the name of any of the items.
  const setFilteredItems: ProjectList = { projects: projects.projects.filter((project: Project) => ( project.name.toLowerCase().includes(searchText) )) }

  // return the filters items.
  if(setFilteredItems)
  {
    console.log("Filtered Projects", setFilteredItems)
    return setFilteredItems
  }
  else
  {
    console.log("Projects: no filter", projects)
    return projects
  }
}

export function SearchFilterProjectPosts(
  projectPosts: ProjectPostList,
  searchText: string
): ProjectPostList {
  // Find if search text exists in the name of any of the items.
  const setFilteredPosts: ProjectPostList = { posts: projectPosts.posts.filter((projectPost: ProjectPost) => ( projectPost.text.toLowerCase().includes(searchText) )) }

  // return the filters items.
  if(setFilteredPosts)
  {
    console.log("Filtered Projects", setFilteredPosts)
    return setFilteredPosts
  }
  else
  {
    console.log("Projects: no filter", projectPosts)
    return projectPosts
  }
}

export function SearchFilterPosts(
  allPosts: AllPostTypeList,
  searchText: string
): AllPostTypeList {
  // Find if search text exists in the name of any of the items.
  let setFilteredPosts: AllPostTypeList = { posts: allPosts.posts.filter((post: AllPostType) => 
    {
      if(post.post_type === "projectpost") {
        let projectPost: ProjectPost = post.data as ProjectPost
        return projectPost.text.toLowerCase().includes(searchText)
      }
      else if(post.post_type === "item") {
        let item: Item = post.data as Item
        return item.name.toLowerCase().includes(searchText)
      }
      else if(post.post_type === "post") {
        let user_post: Post = post.data as Post
        return user_post.text.toLowerCase().includes(searchText)
      }
      else {
        return false
      }

  }) 
  }

  // return the filters items.
  if(setFilteredPosts)
  {
    console.log("Filtered Projects", setFilteredPosts)
    return setFilteredPosts
  }
  else
  {
    console.log("Projects: no filter", allPosts)
    return allPosts
  }
}
