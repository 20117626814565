import React, { useState, useRef } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import ReactCrop, { Crop, PercentCrop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop'
import axios from 'axios'
import { sendAddUser } from '../api/backend-api'

import {UserProfile, defaultUserProfile} from '../interfaces/common-types';
import { useDebounceEffect, canvasPreview } from '../utils/image-handling';
import ValidateEmailRegex from '../utils/validation'
// import AddressAutocomplete from './AddressAutocomplete'
import './error.css'


const MAX_LAST_NAME_LENGTH = 40;
const MAX_FIRST_NAME_LENGTH = 40;

const usStateAbbreviations = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];


export default function Signup({
  signUp
}:{
  signUp: boolean
}) {
  let userProfile: UserProfile = defaultUserProfile
  const location = useLocation();
  const { token } = useParams();
  const { state } = location;
  if(!signUp) {
    
    userProfile = state.userProfile
  }

  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [firstName, setFirstName] = useState<string>(signUp ? "" : userProfile.first_name); 
  const [lastName, setLastName] = useState<string>(signUp ? "" : userProfile.last_name);  
  const [emailAddress, setEmailAddress] = useState(signUp ? "" : userProfile.email);
  const [streetAddress, setStreetAddress] = useState(signUp ? "" : userProfile.street_address);
  const [city, setCity] = useState(signUp ? "" : userProfile.city);
  const [userState, setUserState] = useState(signUp ? "" : userProfile.state);
  const [zipCode, setZipCode] = useState<string>(signUp ? "" : userProfile.zip_code.toString());
  const [profilePicUrl, setProfilePicUrl] = useState(signUp ? "" : userProfile.profile_pic)
  const [profilePic, setProfilePic] = useState<File>();
  const [formError, setFormError] = useState<string>("")
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  
  
  //Validation variables
  const [isFirstNameValid, setIsFirstNameValid] = useState<Boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<Boolean>(true);  
  const [isEmailValid, setIsEmailValid] = useState<Boolean>(true);
  const [isProfilePicValid, setIsProfilePicValid] = useState<Boolean>(false);
  const [doPasswordsMatch, setdoPasswordsMatch] = useState<Boolean>(true);
  const [profilePicError, setProfilePicError] = useState<string>("")

  const navigateToRoot = useNavigate();

  const onSignUpFormSubmit = (event: any) => {
    event.preventDefault();
    setDisableSubmit(true);
    if (password !== password2){
      setFormError("Passwords do not match")
      setdoPasswordsMatch(false);
      setDisableSubmit(false);
      return
    }

    if (!isFirstNameValid ||
        !isLastNameValid ||
        !isEmailValid ||
        (zipCode !== "" && !ValidateZipCode(zipCode))){ 
      setDisableSubmit(false);
      return
    }

    // if (!isProfilePicValid) {
    //   setFormError("Profile picture required")
    // }

    setdoPasswordsMatch(true);

      if(signUp) {
        sendAddUser(signUp, firstName, lastName, emailAddress.toLowerCase(), streetAddress, city, userState, zipCode, profilePic, password, token)
          .then((data: any)=>{
            console.log("Sign up user: ", data)
            if(data.status === axios.HttpStatusCode.Created){
              navigateToRoot('/')
            }
          })
          .catch((error: any)=> {
            // let errString:string = "";
            setDisableSubmit(false);
            if (error.response.status === axios.HttpStatusCode.BadRequest) {
              setFormError(error.response.data)
              console.log("Signup request error: ", error.response.data)
            }
            else {
              let errString:string = "";
              Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
              //Now capitalize this
              errString = errString.charAt(0).toUpperCase() + errString.slice(1);
              setFormError(errString)
              // console.log("Form Error: ", formError)
            }
          })
      }
      else {
        sendAddUser(signUp, firstName, lastName, emailAddress.toLowerCase(), streetAddress, city, userState, zipCode)
          .then((data: any)=>{
            console.log("Update User Profile: ", data)
            if(data.status === axios.HttpStatusCode.Ok){
              navigateToRoot('/profile', {state: { id: userProfile.id}})
            }
          })
          .catch((error: any)=> {
            setDisableSubmit(false);
            let errString:string = "";
            Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
            //Now capitalize this
            errString = errString.charAt(0).toUpperCase() + errString.slice(1);
            setFormError(errString)
          })
      }
  }

  const ValidateFirstName = (name: string) => {
    console.log("validateFirstName");
    if(name.length === 0){
      setIsFirstNameValid(false);
    }
    else if(name.length > MAX_FIRST_NAME_LENGTH){
      setIsFirstNameValid(false);
    }
    else{
      setIsFirstNameValid(true);
    }
    setFirstName(name);
  }

  const ValidateLastName = (name: string) => {
    console.log("validateLastName");
    if(name.length === 0){
      setIsLastNameValid(false);
    }
    else if(name.length > MAX_LAST_NAME_LENGTH){
      setIsLastNameValid(false);
    }
    else{
      setIsLastNameValid(true);
    }
    setLastName(name);
  }

  const ValidateEmailAddress = (email: string) => {
    console.log("validateEmail");
    if(email.length === 0){
      setIsEmailValid(false);
    }
    else{
      setIsEmailValid(ValidateEmailRegex(email));
    }
    setEmailAddress(email);
  }

  const ValidateZipCode = (zipcode_full: string) => {
    const regex = /^\d{5}$/;
    if (regex.test(zipcode_full)) {
      return true;
    }
    else {
      setFormError("Zip code invalid")
      return false;
    }
  }

  const NumericCharOnly = (zipcode_input: string) => {
    const regex = /^\d{0,5}$/;
    if(regex.test(zipcode_input)) {
      setZipCode(zipcode_input)
    }
  }

  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [imgSrc, setImgSrc] = useState('')
  const [showPicUploadModal, setShowPicUploadModal] = useState<boolean>(false);
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const aspect = 1

  const onChange = (percentCrop: PercentCrop) => {
    setCrop(percentCrop)
  }

  const toggleShowPicUploadModal = () => {
    setImgSrc("")
    setProfilePicError("")
    setShowPicUploadModal(!showPicUploadModal);
  }

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const fileList = event.target.files

    if(fileList && fileList.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const newimagesrc = reader.result?.toString()
        setImgSrc(newimagesrc || '')
    })
    reader.readAsDataURL(fileList[0])
    }
    return
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget
    const newCrop = centerCrop(makeAspectCrop(
      {
        // You don't need to pass a complete crop into
        // makeAspectCrop or centerCrop.
        unit: '%',
        width: 90,
      },
      aspect,
      width,
      height
    ),
    width,
    height
    )

    setCrop(newCrop)
  }

useDebounceEffect(
  async () => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      // We use canvasPreview as it's much faster than imgPreview.
      console.log("completedCrop", completedCrop)
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop
      )
    }
  },
  100,
  [completedCrop],
)

  async function onUpdatePicFormSubmit() {
    if (!previewCanvasRef.current) {
      setIsProfilePicValid(false)
      setProfilePicError('Crop canvas does not exist')
      return
    }

    previewCanvasRef.current.toBlob((newImageBlob) => {
      // console.log(newImageBlob)
      if (!newImageBlob) {
        setIsProfilePicValid(false)
        // setProfilePicError('Failed to create')
        const context = previewCanvasRef.current?.getContext("2d")
        // Clear the canvas so it can be re-drawn
        if (previewCanvasRef.current) {
          context?.clearRect(0, 0, previewCanvasRef.current.width, previewCanvasRef.current?.height)
        }
        return
      }
      console.log("width", previewCanvasRef.current?.width, "height", previewCanvasRef.current?.height)
      const picFileName = userProfile.first_name + "_" + userProfile.last_name + "_profile_pic.jpeg"
      const imageFile = new File([newImageBlob], picFileName, {type: "image/jpeg" })
      setProfilePicUrl(URL.createObjectURL(imageFile))
      setProfilePic(imageFile)
      setIsProfilePicValid(true)
      toggleShowPicUploadModal()
    },
    "image/jpeg",
    0.8)
  }

  return (
    <div>
      {signUp ?
      <div>
        <h2>Hello and welcome to Partak!</h2>
        <p className="text-left font-weight-bold">
          Partak is a place where we use sharing to strengthen our communities and friendships. 
          Its a place where we can help save our planet and our pocketbooks by consuming less.
          As many of our parents liked to say, "sharing is caring", and we care about you!
        </p>
        <p className="text-left font-weight-bold">
          As we all know, friendships and communities are built on trust. 
          Because of that we encourage you to fill out all the form fields below.
        </p>
        <p className="text-left">
          <span className="font-weight-bold"></span>
          This information is only for your communities and friends. You won't find any strangers here.  
        </p>
        </div>
      :
      <h1 className="text-left">Update Profile</h1>
      }
      <form onSubmit={onSignUpFormSubmit} method="post" autoComplete='off'>
        <div className="form-group text-left">
          <label htmlFor="firstName" className="font-weight-bold"><span style={{color: 'red'}}>*</span> First Name</label>
          <input
            type="text"
            onChange={(event)=>{ValidateFirstName(event.target.value)}} 
            className="form-control" 
            id="firstName" 
            aria-describedby="firstNameHelp"
            value={firstName} 
            placeholder="First Name" 
            required
          />
        </div>
        {!isFirstNameValid && 
          <label className="form-error">Invalid First Name!</label>

        }
        <div className="form-group text-left">
          <label htmlFor="lastName" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Last Name</label>
          <input 
            type="text"
            onChange={(event)=>{ValidateLastName(event.target.value)}}
            className="form-control"
            id="lastName"
            value={lastName}
            placeholder="Last Name" 
            required
          />
        </div>
        {!isLastNameValid &&
          <label className="form-error">Invalid Last Name!</label>
        }
        <div className="form-group text-left">
          <label htmlFor="emailAddress" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Email Address</label>
          <input
            type="text"
            onChange={(event)=>{setEmailAddress(event.target.value)}}
            onBlur={(event)=>{ValidateEmailAddress(event.target.value)}}
            className="form-control"
            id="emailAddress"
            value={emailAddress}
            placeholder="Email Address"
            required
          />
        </div>
        {!isEmailValid && 
          <label className="form-error">Invalid Email Address!</label>
        }
        <div className="form-group text-left">
          <label htmlFor="address" className="font-weight-bold">Street Address (+10 coins for completion)</label>
          <input
              type="text"
              onChange={(event)=>{setStreetAddress(event.target.value)}}
              className="form-control"
              id="address"
              value={streetAddress}
              placeholder="Street Address"
            />
            {/* <AddressAutocomplete /> */}
        </div>
        <div className="form-group text-left">
          <label htmlFor="city" className="font-weight-bold">City (+5 coins for completion)</label>
          <input
              type="text"
              onChange={(event)=> {setCity(event.target.value)}}
              className="form-control"
              id="city"
              value={city}
              placeholder="City"
            />
        </div>
        <div className="form-group text-left">
          <label htmlFor="state"className="font-weight-bold">State (+5 coins for completion)</label>
          <select
            onChange={(event)=>{setUserState(event.target.value)}}
            className="custom-select"
            id="state"
            value={userState}
            >
            <option value="" disabled selected hidden>Select a State</option>
            {usStateAbbreviations.map((stateAbbreviation) => (
            <option key={stateAbbreviation} value={stateAbbreviation}>
              {stateAbbreviation}
            </option>))}
          </select>
        </div>
        <div className="form-group text-left">
          <label htmlFor="zipcode" className="font-weight-bold">Zip Code (5-digit) (+5 coins for completion)</label>
          <input
              type="text"
              onChange={(event)=>{NumericCharOnly(event.target.value)}}
              className="form-control"
              id="zipcode"
              value={zipCode}
              placeholder="Zip Code"
              maxLength={5}
              minLength={5}
            />
        </div>

        {signUp ? 
        <div className="form-group text-left">
          {isProfilePicValid ? <img className="profile-icon rounded-circle text-dark" src={profilePicUrl} alt="profile pic"></img> : <></>}
            <div className="mt-3">
              <p className="font-weight-bold">Profile Picture (+25 coins for adding a profile picture)</p>
              <button type="button" className='btn btn-success' onClick={toggleShowPicUploadModal} data-toggle="modal" data-target="#changeprofilepic">Upload Profile Picture</button>
            </div>
        </div>
        :
        <div></div>}
        {signUp ? 
        <div className="form-group text-left">
          <label htmlFor="password" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Password</label>
          <input 
            type="password"
            onChange={(event)=>{setPassword(event.target.value)}}
            className="form-control" 
            id="password" 
            placeholder="Password" 
            required
          />
        </div>
        :
        <div></div>}

        {!doPasswordsMatch && <div><label className="form-error">Passwords don't match!</label></div>}
        {signUp ? 
        <div className="form-group text-left">
          <label htmlFor="password" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Retype Password</label>
          <input 
            type="password"
            onChange={(event)=>{setPassword2(event.target.value)}}
            className="form-control" 
            id="password2" 
            placeholder="Password" 
            required
          />
        </div>
        :
        <div></div>}
        <label className="form-error">{formError}</label>
        <button type="submit" className="btn btn-success btn-block my-2" disabled={disableSubmit}>Sign Up</button>
      </form>

      <Modal 
        show={showPicUploadModal} 
        onHide={toggleShowPicUploadModal} 
        backdrop="static"
        keyboard={false} >
              <Modal.Header closeButton>
                <Modal.Title>Upload Picture</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <form className="form">
                <div className="form-group text-left">
                  <label htmlFor="itemImage">Upload Image</label>
                  <input type="file" className="form-control-file" id="itemImage" 
                    accept="image/png, image/jpeg, image/jpg" onChange={handleFileChange} required/>
                </div>
              </form>
              {!!imgSrc && (
                <>
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => onChange(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={1}
                >
                  <img
                    ref={imgRef}
                    alt="Crop profile"
                    src={imgSrc}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
                <canvas 
                  ref={previewCanvasRef}
                  id="myCanvas"
                  width={0}
                  height={0}
                  style={{border: "1px solid #000000", display: "none" }}
                ></canvas>
                </>
              )}
              </Modal.Body>
              <Modal.Footer>
                <label>{profilePicError}</label>
                <button type="button" className="btn btn-secondary" onClick={toggleShowPicUploadModal}>Cancel</button>
                <button type="button" className="btn btn-success" onClick={onUpdatePicFormSubmit}>Submit</button>
              </Modal.Footer>
            </Modal>
    </div>
  )
}

