import { useState } from 'react'
import ValidateEmailRegex from '../utils/validation'
import { resetPassword } from '../api/backend-api'


export default function PasswordReset() {
  const [email, setEmail] = useState<string>("")
  const [errorDisplay, setErrorDisplay] = useState<string>("")
  const [isEmailvalid, setIsEmailValid] = useState<boolean>(false)
  const [resetSent, setResetSent] = useState<boolean>(false)
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)

  const onPassResetFormSubmit = (event: any) => {
    event.preventDefault();
    setDisableSubmit(true);
    if (!isValidForm()){
      return
    }
    resetPassword(email)
  }

  const ValidateEmailAddress = (email: string) => {
    if(email.length === 0){
      setIsEmailValid(false);
    }
    else{
      if(ValidateEmailRegex(email)) {
        setIsEmailValid(true);
        setDisableSubmit(false);
      }
      else {
        setIsEmailValid(false);
        setDisableSubmit(true);
      }
    }
    setEmail(email);
  }

  const isValidForm = () => {
    if (isEmailvalid) {
      setErrorDisplay("");
      setResetSent(true);
      return (true);
    }

    setErrorDisplay("Invalid Email");
    return (false);
  }

  return (
    <>
    {!resetSent ?
    <div className="padding_section container">
    <div className="row justify-content-center">
        <div className="col-md-5">
            <div className="card text-left">
                <div className="card-body">
                    <h1 className="text-start">Password Reset</h1>
                    <p className="text-danger">{errorDisplay ? errorDisplay : ""}</p>
                    <form onSubmit={onPassResetFormSubmit} method="post" className="d-block">
                      <div className="form-group">
                        <label htmlFor="reset_email">Email:</label>
                        <input 
                          className="form-control" 
                          onChange={(event)=>{setEmail(event.target.value)}}
                          onBlur={(event)=>{ValidateEmailAddress(event.target.value)}}
                          type="text" 
                          id="reset_email" 
                          name="reset_email"
                          aria-describedby="errorHelp"
                        />
                      </div>
                      <button type="submit" className="btn btn-success btn-block mt-2" disabled={disableSubmit}>Send Password Reset Email</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>
    :
    <div>
      <h2 className="my-3">Password Reset Requested</h2>
      <p>You should receive an email shortly. Check your email for instruction to reset your password.</p>
    </div>
  }
  </>
  )
}