import React, { useState } from 'react'
import { sendAddProject, sendUpdateProject } from '../api/backend-api';
import { useNavigate, useLocation } from 'react-router-dom';
import { Project, defaultProjectDetails } from '../interfaces/common-types';
import axios from 'axios';

import './error.css'
import './item-details.css'


const MAX_DESCRIPT_LENGTH = 500;
const MAX_PROJECT_NAME_LENGTH = 100;


export default function AddProject({
  newProject
}:{
  newProject: boolean
}) {
  const location = useLocation();
  const { state } = location;

  const projectDetails = newProject ? defaultProjectDetails : state.project as Project

  const [projectName, setProjectName] = useState(newProject ? "" : projectDetails.name); 
  const [projectDescription, setProjectDescription] = useState(newProject ? "" : projectDetails.description);
  const [projectId] = useState(newProject ? 0 : projectDetails.id)
  const [formError, setFormError] = useState<string>("")
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const navigateToRoot = useNavigate()

  const onAddProjectFormSubmit = (event: any) => {
    event.preventDefault();
    setDisableSubmit(true)

    // No form validation assuming the inputs are not blank
    // console.log("form valid")

    if(newProject) {
      sendAddProject(projectName, projectDescription)
        .then((data: any)=>{
          // console.log("add Project Request data: ", data)
          if(data.status === axios.HttpStatusCode.Created){
            navigateToRoot('/projects/user_projects')
          }
          else{
            console.log("Error:", data.status)
          }
        })
        .catch((error: any)=> {
          let errString:string = "";
          Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
          //Now capitable this
          errString = errString.charAt(0).toUpperCase() + errString.slice(1);
          setFormError(errString)
          console.log("Form Error: ", formError)
          setDisableSubmit(false)
        })
    }
    else if(!newProject) {
      sendUpdateProject(projectId, projectName, projectDescription)
        .then((data: any)=>{
          // console.log("Update Project Request data: ", data)
          if(data.status === axios.HttpStatusCode.Ok){
            navigateToRoot('/projects/user_projects')
          }
          else{
            console.log("Error:", data.status)
          }
        })
        .catch((error: any)=> {
          // console.log("Login Error: ", error.response.data)
          let errString:string = "";
          Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
          //Now capitable this
          errString = errString.charAt(0).toUpperCase() + errString.slice(1);
          setFormError(errString)
          console.log("Form Error: ", formError)
          setDisableSubmit(false)
        })
    }
  }

  return (
    <div>
      <h3 className="text-left">{newProject ? "Add Project" : "Update Project"}</h3>
      <form onSubmit={onAddProjectFormSubmit} method="post">
        <div className="form-group text-left">
          <label htmlFor="projectName" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Name</label>
          <input 
            type="text" 
            onChange={(event)=>{setProjectName(event.target.value.slice(0,MAX_PROJECT_NAME_LENGTH))}} 
            className="form-control" id="projectName" aria-describedby="nameHelp"
            value={projectName}
            placeholder="Project Name" 
            required
          />
          <small id="nameHelp" className="form-text text-muted ml-1"></small>
        </div>
        <div className="form-group text-left">
          <label htmlFor="projectDescription" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Description</label>
          <textarea 
            onChange={(event)=>{setProjectDescription(event.target.value.slice(0, MAX_DESCRIPT_LENGTH))}} 
            className="form-control" 
            id="projectDescription" 
            rows={3} 
            aria-describedby="descriptionHelp"
            value={projectDescription} 
            placeholder="Project Description" 
            required
          ></textarea>
          <small id="descriptionHelp" className="form-text text-muted ml-1">Describe your project. {projectDescription.length}/{MAX_DESCRIPT_LENGTH} characters</small>
        </div>
        
        <button type="submit" className="btn btn-primary btn-lg btn-block" disabled={disableSubmit}>Add Project</button>
        <label className="form-error">{formError}</label>
      </form>
    </div>
  )
}