import React, {useState, useEffect } from 'react'
import CardCollection from './collection-items'
import { getItemsFeed, inSearchOfRequest } from '../api/backend-api'
import {ItemList} from '../interfaces/common-types'
import { SearchItems } from '../utils/search'
import Modal from "react-bootstrap/Modal"

import './home.css'

export default function ItemsFeed({
  searchText
}: {
  searchText: string
}) {
  const [items, setItems] = useState<ItemList>()
  const [showIsoModal, setShowIsoModal] = useState<boolean>(false)
  const [requestMessage, setRequestMessage] = useState<string>('')
  const [showSentModal, setShowSentModal] = useState<boolean>(false)

  const toggleShowIsoModal = () => {
    setShowIsoModal(!showIsoModal);
  }

  const sendIsoMessage = () => {
    inSearchOfRequest(requestMessage);
    toggleShowIsoModal();
    toggleShowSentModal();
  }

  const toggleShowSentModal = () => {
    setShowSentModal(!showSentModal);
  }

  useEffect(() => {
    getItemsFeed()
    .then((response) => {
      // console.log("Items feed Items response: ", response.status)
      const itemsFeed: ItemList = { items: response.data }
      setItems(itemsFeed)
    })
    .catch((error) => console.log("Items feed Items error: ", error.response.status))
  }, [])
  
  if(!items)
  {
    return(
      <div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      )
  }

  return (
    <div>
      <p className="text-left font-weight-bold">The Home feed contains the items that your friends and communities have added to Partak.</p>
      <CardCollection 
        items={SearchItems(items, searchText)}
        searchText={searchText}
        homeFeed={true}
      />
        <div className="container pb-3">
        <div className="row">
          <div className="col-lg-8">
            <p className="text-left">Not finding what your looking for? <br />Let your community know what what you are searching for.</p>
          </div>
          <div className="col-lg-4">
            <button className="btn btn-success" onClick={toggleShowIsoModal}>In Search Of: Item</button>
          </div>
        </div>
      </div>
      <Modal show={showIsoModal} onHide={toggleShowIsoModal} >
        <Modal.Header closeButton>
          <Modal.Title>Send In Search Of Item Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form">
            <div className="form-row text-left mb-2">
            <p className="text-left">Reach out to your friends to see if they have the item you are looking for.</p>
            <p>By typing the item you are looking for in the box below and pressing send, a chat message will be sent to everyone in the groups you belong to.</p>
            <p className="text-left">If someone has the item, they can respond to your message. Then add the item for you to borrow.</p>
              <label className="col">In Search Of:</label>
              <textarea
              onChange={(event)=>{setRequestMessage(event.target.value)}} 
              className="form-control"
              id="requestMessageBox" 
              rows={1}
              value={requestMessage} 
              placeholder="Item Description" 
              >
                </textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={toggleShowIsoModal}>Close</button>
          <button type="button" className="btn btn-success" onClick={sendIsoMessage}>Send</button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSentModal} onHide={toggleShowSentModal} >
        <Modal.Header closeButton>
          <Modal.Title>Message Sent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your In Search Of message has been sent to your communities.</p>
          <p>Check your Chat for responses.</p>
        </Modal.Body>
      </Modal>
    </div>
  )
}