import React from 'react'
import { NavLink } from 'react-router-dom'
import {UserProfileLite} from '../interfaces/common-types'

export default function UserProfileLink({
  userProfileInfo
}: {
  userProfileInfo: UserProfileLite
}) {
  return (
    <NavLink to={{pathname: '/profile'}} state={{ id: userProfileInfo.id}} className="text-decoration-none text-dark">
      <img className="profile-icon rounded-circle text-dark" src={userProfileInfo.profile_pic} alt="profile pic"></img> { userProfileInfo.first_name } {userProfileInfo.last_name}
    </NavLink>
  )
}