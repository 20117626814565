import React, {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom"
import { getMyGroups } from '../api/backend-api'
import { Group, GroupList, UserProfile } from '../interfaces/common-types'
import UserProfileLink from './user-profile-link'

export default function Groups({
  searchText,
  userProfile
}: {
  searchText: string,
  userProfile: UserProfile
}) {
  const [groups, setGroups] = useState<GroupList>()

  useEffect(() => {
    getMyGroups()
    .then((response) => {
      const formattedJsonResponse: GroupList = {'groups': response.data}
      // console.log("My Groups", formattedJsonResponse)
      setGroups(formattedJsonResponse)
    })
    .catch((error) => console.log(error))
  }, [])

  if(!groups)
  {
    return(
      <div>
        <h3 className="text-left">Communities</h3>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      )
  }

  return (
    <div>
      <h3 className="text-left">Communities</h3>
      <p className="text-left"><span className="font-weight-bold">The Communities page lists all the communities that you are currently a part of.</span></p>
      {groups.groups ? groups.groups.map((group: Group) => (
        <NavLink to={{pathname: '/community'}} state={{ communityDetails: group}} className="text-decoration-none" key={group.id}>
        <div className="card bg-light shadow mb-3" key={group.group_name}>
        <div className="card-body p-0">
        <div className="card-header p-1">
          <h4 className="font-weight-bold text-dark text-left pl-3">{group.group_name}</h4>
        </div>
        <div className="row pl-3">
          <div className="col-sm-8 text-left text-nowrap">
            <table className="table table-sm table-borderless font-weight-bold table-light">
              <tbody>
                <tr>
                  <th scope="row" className="align-middle">Group Owner:</th>
                  <td className="text-left align-middle"><UserProfileLink userProfileInfo={group.group_owner}></UserProfileLink></td>
                </tr>
                <tr>
                  <th scope="row" className="align-middle">Description:</th>
                  <td className="text-left align-middle">{group.description}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
        </div>
        </NavLink>
      )): 
      <div></div>}
      <div>
      <NavLink to={{pathname: '/friends'}}  className="text-decoration-none">
        <div className="card bg-light shadow mb-3" key={"friends"}>
        <div className="card-body p-0">
        <div className="card-header p-1">
          <h4 className="font-weight-bold text-dark text-left pl-3">Friends</h4>
        </div>
        <div className="row pl-3">
          <div className="col-sm-8 text-left text-nowrap">
            <table className="table table-sm table-borderless font-weight-bold table-light">
              <tbody>
                <tr>
                  <th scope="row" className="align-middle">Description:</th>
                  <td className="text-left align-middle">Your Partak friends.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
        </div>
        </NavLink>
        </div>
        <div>
        <NavLink to={{pathname: '/add_modify_group'}} state={{ newGroup: true }}className="btn btn-success float-right mb-3 mr-3">Create Group</NavLink>
        </div>
    </div>
  )
}
