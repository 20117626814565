import React, { useState, useContext, useEffect } from 'react'
import { NavLink } from "react-router-dom"
import Carousel from 'react-bootstrap/Carousel'
import Pagination from './pagination'

import UserProfileLink from './user-profile-link'
import {AllPostType, AllPostTypeList, Item, ProjectPost, Post, CommentInfo} from '../interfaces/common-types'
import { UserContext } from '../contexts/userContext'
import { addComment } from '../api/backend-api'

import './card-collection.css'

const MAX_COMMENT_LENGTH = 250;

export default function HomeFeedCardCollection({
  posts,
  refreshFeed
}: {
  posts: AllPostTypeList
  refreshFeed: () => void
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(5)
  const {userProfile} = useContext(UserContext)
  const [comments, setComments] = useState<CommentInfo[]>([])

  const numberOfProjects = posts.posts.length
  const numberOfPages = Math.ceil(numberOfProjects / recordsPerPage)
  const indexOfLastRecord = currentPage * recordsPerPage
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage  
  const currentRecords = posts.posts.slice(indexOfFirstRecord, indexOfLastRecord)

  useEffect(() => {
    const initialComments: CommentInfo[] = []

    for (let i = 0; i < posts.posts.length; i++) {
      initialComments.push({comment_text: "", comment_button_disabled: true})
    }

    console.log("initial comments", initialComments)
    setComments(initialComments)
  },[posts.posts.length])

  console.log("Posts", currentRecords)

  const getItemImage = (item: Item) => {
    if(item.images.length >= 1) {
      // console.log("item image", item.images[0].image)
      return (
        item.images[0].image
      )
    }
    else {
      return("")
    }
  }

  const onCommentFormSubmit = (event: any, postId: number, idx: number) => {
    event.preventDefault()
  
    console.log("Send Comment to backend", postId, comments[idx].comment_text)
    addComment(postId, comments[idx].comment_text)
    const tempComments = [...comments]
    tempComments[idx].comment_text = ""
    tempComments[idx].comment_button_disabled = true
    setComments(tempComments) 
    refreshFeed()
  }

  const updateCommentText = (index: number, text: string) => {
    const updatedComments = [...comments]
    updatedComments[index].comment_text = text
    if(text.length > 0) {
      updatedComments[index].comment_button_disabled = false
    }
    else {
      updatedComments[index].comment_button_disabled = true
    }
    console.log("updated comment", updatedComments[index].comment_text, updatedComments[index].comment_button_disabled)

    setComments(updatedComments) 
  }

  const cardSelect = (cardData: AllPostType, idx: number) => {
    console.log("card data", cardData.data)
    if (cardData.post_type === "item") {
      // console.log("New Item Card")
      return itemCard(cardData.data as Item)
    }
    else if (cardData.post_type === "projectpost") {
      // console.log("New Project Post Card")
      return projectPostCard(cardData.data as ProjectPost, idx)
    }
    else if (cardData.post_type === "post")
    {
      // console.log("New Post Card")
      return postCard(cardData.data as Post, idx)
    }
    else {
      console.log("Unknown type:", cardData.post_type)
      return <></>
    }
    
  }

  const itemCard = (item: Item) => {
    return(
      <NavLink to={{pathname: '/item_details'}} state={{ id: item.id}} className="text-decoration-none">
      <div className="card-body p-0">
        <div className="card-header">
            <div className="row">
              <div className="col-8 font-weight-bold text-dark text-left">{item.name}</div>
              <div className="col-4 font-weight-bold text-dark text-right"><i className="fas fa-coins"></i> {item.cost} per {item.cost_unit}</div>
            </div>
        </div>
        <div className="row">
          <div className="col mh-100 item-image d-flex justify-content-end">
            <img className="item-card-image float-right" src={getItemImage(item)} alt={item.name}/>
          </div>
          <div className="col-sm-8 text-left word-break card-text">
            <table className="table table-sm table-borderless">
              <tbody>
                <tr>
                  <th scope="row">Owner:</th>
                  <td className="text-dark"><UserProfileLink userProfileInfo={item.owner}></UserProfileLink></td>
                </tr>
                <tr>
                  <th scope="row">Status:</th>
                  <td>{item.status}</td>
                </tr>
                <tr>
                  <th scope="row">Description:</th>
                  <td className="text-dark">{item.description}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </NavLink>
    )
  }

  const projectPostCard = (post: ProjectPost, idx: number) => {
    return(
      <div className="card-body p-0">
          <div className="card-header">
            <NavLink to={{pathname: '/project_details'}} state={{ projectId: post.project.id}} className="text-dark text-decoration-none">
              <div className="row">
                <h5 className="font-weight-bold text-dark text-left m-0">{post.project.name}</h5>
              </div>
            </NavLink>
          </div>
        <div className="row">
          <div className="col text-left word-break card-text ml-3">
            <table className="table table-sm table-borderless font-weight-bold">
              <tbody>
                <tr>
                  <td>
                    <UserProfileLink userProfileInfo={post.post_creator}></UserProfileLink>
                  </td>
                </tr>
                <tr>
                  <td className="text-left">{post.text}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        { post.images.length > 0 ?
          <div className="row">
            <div className="col mh-100 post-image d-flex justify-content-center">
            <Carousel variant={'dark'} slide={false} interval={null}>
              {post.images.map((image, i) => (
                <Carousel.Item key={i}>
                  <img className="post-card-image" src={image.image} alt={image.id.toString()}/>
                </Carousel.Item>
              ))
              }
            </Carousel>
            </div>
          </div>
          : <></>
        }
        <hr />
        { post.comments && post.comments.length > 0 ?
          <div className="row m-1">
            {post.comments.map((comment, i) => (
              <div className="d-flex">
                <div className="m-1 align-top">
                  <NavLink to={{pathname: '/profile'}} state={{ id: comment.commenter.id}} className="text-decoration-none">
                    <img className="profile-icon rounded-circle text-dark" src={comment.commenter.profile_pic} alt="profile pic"></img> 
                  </NavLink>
                </div>
                <div className="flex-grow-1 text-left">
                  <NavLink to={{pathname: '/profile'}} state={{ id: comment.commenter.id}} className="text-decoration-none">
                    <div className="text-muted">{ comment.commenter.first_name } {comment.commenter.last_name}</div>
                  </NavLink>
                  <p>{comment.text}</p>
                </div>
              </div>
            ))}
            </div>
          : <></>
        }
        <form onSubmit={(e) => onCommentFormSubmit(e, post.id, idx)}>
          <div className="form-group">
            <div className="row m-1">
              <div className="d-flex">
                <div className="m-1 align-self-center">
                  <img className="profile-icon rounded-circle text-dark " src={userProfile.profile_pic} alt="profile pic"></img>
                </div>
                <div className="flex-grow-1">
                  <input 
                    type="text" 
                    onChange={(event)=>{updateCommentText(idx, event.target.value.slice(0,MAX_COMMENT_LENGTH))}} 
                    className="form-control" id="itemName" aria-describedby="nameHelp"
                    value={comments[idx].comment_text}
                    placeholder="Add a comment" 
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row m-1">
              <div className='d-flex justify-content-end'>
                <button type="submit" 
                  className="btn btn-success" 
                  disabled={comments[idx].comment_button_disabled} 
                  hidden={comments[idx].comment_button_disabled}>
                    Comment
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  const postCard = (post: Post, idx: number) => {
    return(
      <div className="card-body p-0">
        <div className="row">
          <div className="col text-left word-break card-text ml-3">
            <table className="table table-sm table-borderless font-weight-bold">
              <tbody>
                <tr>
                  <td>
                    <UserProfileLink userProfileInfo={post.post_creator}></UserProfileLink>
                  </td>
                </tr>
                <tr>
                  <td className="text-left">{post.text}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        { post.images.length > 0 ?
          <div className="row">
            <div className="col mh-100 post-image d-flex justify-content-center">
            <Carousel variant={'dark'} slide={false} interval={null}>
              {post.images.map((image, i) => (
                <Carousel.Item key={i}>
                  <img className="post-card-image" src={image.image} alt={image.id.toString()}/>
                </Carousel.Item>
              ))
              }
            </Carousel>
            </div>
          </div>
          : <></>
        }
        <hr />
        { post.comments && post.comments.length > 0 ?
          <div className="row m-1">
            {post.comments.map((comment, i) => (
              <div className="d-flex">
                <div className="m-1 align-top">
                  <NavLink to={{pathname: '/profile'}} state={{ id: comment.commenter.id}} className="text-decoration-none">
                    <img className="profile-icon rounded-circle text-dark" src={comment.commenter.profile_pic} alt="profile pic"></img> 
                  </NavLink>
                </div>
                <div className="flex-grow-1 text-left">
                  <NavLink to={{pathname: '/profile'}} state={{ id: comment.commenter.id}} className="text-decoration-none">
                    <div className="text-muted">{ comment.commenter.first_name } {comment.commenter.last_name}</div>
                  </NavLink>
                  <p>{comment.text}</p>
                </div>
              </div>
            ))}
            </div>
          : <></>
        }
        <form onSubmit={(e) => onCommentFormSubmit(e, post.id, idx)}>
          <div className="form-group">
            <div className="row m-1">
              <div className="d-flex">
                <div className="m-1 align-self-center">
                  <img className="profile-icon rounded-circle text-dark " src={userProfile.profile_pic} alt="profile pic"></img>
                </div>
                <div className="flex-grow-1">
                  <input 
                    type="text" 
                    onChange={(event)=>{updateCommentText(idx, event.target.value.slice(0,MAX_COMMENT_LENGTH))}} 
                    className="form-control" id="itemName" aria-describedby="nameHelp"
                    value={comments[idx].comment_text}
                    placeholder="Add a comment" 
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row m-1">
              <div className='d-flex justify-content-end'>
                <button type="submit" 
                  className="btn btn-success" 
                  disabled={comments[idx].comment_button_disabled}
                  hidden={comments[idx].comment_button_disabled}>
                    Comment
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  if (comments.length !== posts.posts.length) {
    return <></>
  }
  else  {
    return (
      <div className="py-2">
        <Pagination
          nPages={numberOfPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        {currentRecords.length > 0 ? currentRecords.map((post: AllPostType, idx: number) => (
          <div className="card post-card bg-white shadow mb-3 rounded" key={post.data.id}>
            {cardSelect(post, idx)}
          </div>
        )): 
        <h4 className="text-left">No posts yet</h4> }
        <Pagination
          nPages={numberOfPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div> 
    )
  } 
}