import React, { useContext, useEffect, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'
import { logout } from '../api/backend-api';
import { UserContext } from '../contexts/userContext';
import { UserUnreadContext } from '../contexts/userUnreadContext';
import { getUnreadCount } from '../api/backend-api'
import partakLogo from '../static/SharingHands_Green.png'
import './top-bar.css'

export default function TopBar(
  {
    onChangeSearchText,
    refreshIcon
  }: {
    onChangeSearchText: (text: string) => void,
    refreshIcon: boolean
  }) {
  // console.log("Reloaded TopBar");
  const userProfile = useContext(UserContext);
  const {unreadCount, updateUnreadCount} = useContext(UserUnreadContext)

  refreshIcon = false;
  const refreshInterval = 2000;

  const RefreshUnreadCount = useCallback(() => {
    if(userProfile.userProfile.logged_in && updateUnreadCount) {
    getUnreadCount()
      .then((response) => {
        // console.log("Unread Count Response", response)
        const currentUnreadCount: number = response.data.unread_count
        updateUnreadCount(currentUnreadCount)
      })
      .catch((error) => console.log("Unread Count error: ", error.response.status))
    }
  }, [userProfile.userProfile.logged_in])

  useEffect(() => {
    RefreshUnreadCount()
    const updateInterval = setInterval(RefreshUnreadCount, refreshInterval)
    
    return () => clearInterval(updateInterval)
  }, [RefreshUnreadCount])

  return (
    <>
    <Navbar bg="light" fixed="top">
      <Container>
        <Navbar.Brand href="/" className="d-flex justify-content-start align-items-center">
          <img src={partakLogo} alt="logo" width="34" height="32" />
          { userProfile.userProfile.logged_in ?
            <div className="topbar-brand d-none d-md-block"> Part&#257;k</div>
          :
            <div className="topbar-brand d-block"> Part&#257;k</div>
          }
        </Navbar.Brand>
        { userProfile.userProfile.logged_in ?
        <>
          <div className="form-group pt-3 d-flex flex-grow-1">
            <input className="form-control" type="text" placeholder="search Partak" onChange={(e) => {onChangeSearchText(e.target.value.toLowerCase())}}></input>
          </div>
          <Nav className="flex-row">
            <Nav.Item>
              <NavLink to={{pathname: '/notifications'}} className="text-decoration-none text-dark d-none d-md-block mt-3 ml-2"><i className="far fa-bell fa-xl iconClass">
                {(unreadCount>0) ? <span className="badge rounded-pill badge-notification badge-danger">{unreadCount}<span className="visually-hidden">unread messages</span></span> : <span></span>}
                </i>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={{pathname: '/karma'}} className="text-decoration-none text-dark d-block mt-3 ml-2">
                <p className="mt-3 ml-2 text-nowrap"><i className="fas fa-coins"></i><span className="d-none d-md-inline"> Karma:</span> {userProfile.userProfile.coins}</p>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <p className="mt-3 ml-2 d-none d-md-block">{userProfile.userProfile.first_name} {userProfile.userProfile.last_name}</p>
            </Nav.Item>
            <NavDropdown className="text-dark mt-1" align='end' title={<img className="profile-icon rounded-circle text-dark" src={userProfile.userProfile.profile_pic} alt="icon" />}>
              <NavDropdown.ItemText className="dropdown-item"><NavLink to={{pathname: '/profile'}} state={{ id: userProfile.userProfile.id}} className="text-decoration-none text-dark">Your Profile</NavLink></NavDropdown.ItemText>
              {userProfile.userProfile.is_staff ? <NavDropdown.ItemText className="dropdown-item"><NavLink to={{pathname: '/send_signup_link'}}  className="text-decoration-none text-dark">Send Invite</NavLink></NavDropdown.ItemText> : <></>}
              <NavDropdown.Item href="/" className="dropdown-item" onClick={(e) => logout()}>Log out</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </>
        :
        <>
        </>  
        }
      </Container>
    </Navbar>
    </>
  )
}