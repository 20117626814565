import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faBoxesStacked, faCoins, faCirclePlus, faPeopleCarryBox, faHammer } from '@fortawesome/free-solid-svg-icons'

export const nav_sidebar_links = [
{
  title: 'Karma: ',
  path: 'karma',
  icon: <FontAwesomeIcon icon={faCoins} />,
  activeClassName: 'navbar-active',
  inactiveClassName: 'navbar-inactive'
},
{
  title: 'Home',
  path: '/',
  icon: <FontAwesomeIcon icon={faHouse} />,
  activeClassName: 'navbar-active',
  inactiveClassName: 'navbar-inactive'
},
{
  title: 'Projects',
  path: 'projects',
  icon: <FontAwesomeIcon icon={faHammer} />,
  activeClassName: 'navbar-active',
  inactiveClassName: 'navbar-inactive'
},
{
  title: 'Items',
  path: 'items',
  icon: <FontAwesomeIcon icon={faBoxesStacked} />,
  activeClassName: 'navbar-active',
  inactiveClassName: 'navbar-inactive'
},
{
  title: 'Add',
  path: 'add',
  icon: <FontAwesomeIcon icon={faCirclePlus} />,
  activeClassName: 'navbar-additem-active',
  inactiveClassName: 'navbar-additem-inactive'
},
{
  title: 'Chat',
  path: 'messages',
  icon: <i className="fas fa-comments iconClass"></i>,
  activeClassName: 'navbar-active',
  inactiveClassName: 'navbar-inactive'
},
{
  title: 'Groups',
  path: 'groups',
  icon: <FontAwesomeIcon icon={faPeopleCarryBox} />,
  activeClassName: 'navbar-active',
  inactiveClassName: 'navbar-inactive'
},
{
  title: 'Alerts',
  path: 'notifications',
  icon: <i className="far fa-bell iconClass"></i>,
  activeClassName: 'navbar-active',
  inactiveClassName: 'navbar-inactive'
}

]


