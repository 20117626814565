import React from 'react'
import Login from './login'

import './landing-page.css'

export default function LandingPage() {

  return (
    <>
      <div className="pt-3 fixed-md-top">
        <div className="container-fluid padding_section">
          <div className="row">
            <div className="col-md-5">
              <div className="shadow rounded opacity">
                <h1 className="display-5 text-dark"><strong>Welcome to Part&#257;k!</strong></h1>
                <div className="text-dark px-3 font-weight-bold pb-3">The social network built for community, not entertainment.</div>
              </div>
            </div> 
          </div>
          <Login />
        </div>
      </div>
      <div className="rightside rounded">
        <div className="padding_section"></div>
        <div className="background-style landing-image1">
          <div className="container-fluid h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col">
                <div className="mt-3 shadow rounded opacity">
                  <h1 className="p-2 display-6 text-dark">At Part&#257;k we believe communities are built upon people helping each other.</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="background-style landing-image2">
          <div className="container-fluid h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col">
                <div className="mt-3 shadow rounded opacity">
                  <h1 className="display-6 text-dark">Give or Get Advice</h1>
                  <div className="d-flex pl-3 align-items-center">
                    <i className="fas fa-handshake fa-3x text-primary"></i>
                    <p className="p-2 font-weight-bold text-left">
                      We all need a hand once in a while. <br />
                      Someone you know probably has run into the same problem as you. <br />
                      Just reach out to your network and get a hand.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="background-style landing-image3">
          <div className="container-fluid h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col">
                <div className="mt-3 shadow rounded opacity">
                  <h1 className="display-6 text-dark">Borrow the Right Tool for the Job</h1>
                  <div className="d-flex pl-3">
                    <i className="fas fa-money-bill-wave fa-3x text-success"></i>
                    <p className="p-2 font-weight-bold text-left">Save money by borrowing, instead of buying!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="background-style landing-image4">
          <div className="container-fluid h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col">
                <div className="mt-3 shadow rounded opacity">
                  <h1 className="display-6 text-dark">Document Progress on a Project</h1>
                  <div className="d-flex pl-3 align-items-center">
                    <i className="fas fa-camera fa-3x text-info"></i>
                    <p className="p-2 font-weight-bold text-left">
                      Show off the progress or final result of a project are working on. <br />
                      Give others kudos on their cool projects.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="background-style landing-image5">
          <div className="container-fluid h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col">
                <div className="mt-3 shadow rounded opacity">
                  <h1 className="display-6 text-dark">Connect Over Common Hobbies</h1>
                  <div className="d-flex pl-3 align-items-center">
                    <i className="fas fa-hands-clapping fa-3x"></i>
                    <p className="p-2 font-weight-bold text-left">
                      Show off the progress or final result of a project are working on. <br />
                      Give others kudos on their cool projects.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="background-style landing-image6">
          <div className="container-fluid h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col">
                <div className="mt-3 shadow rounded opacity">
                  <h1 className="display-6 text-dark">Together we have more!</h1>
                  <div className="d-flex pl-3 align-items-center">
                    <div className="p-3 align-self-start">
                      <i className="fas fa-hand-holding-heart fa-3x text-danger"></i>
                    </div>
                    <div className="p-3 align-self-center font-weight-bold">Build friendships when you share, care, and Part&#257;k!</div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="shadow rounded opacity">
                  <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
                  <form action="https://app.convertkit.com/forms/6158683/subscriptions" 
                    className="seva-form formkit-form" 
                    method="post" 
                    data-sv-form="6158683" 
                    data-uid="cc51518ef5" 
                    data-format="inline" 
                    data-version="5" 
                    data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;fathom&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}" 
                    min-width="400 500 600 700 800" 
                  >
                    <div className="formkit-background" style={{opacity: "0.2"}}>
                    </div>
                    <div data-style="minimal">
                      <div className="formkit-header" data-element="header" >
                        <h2>Join the Waitlist</h2>
                      </div>
                      <div className="formkit-subheader" data-element="subheader" >
                        <p>Send us your email and we will keep you up to date on progress and when you can sign-up.</p>
                      </div>
                      <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
                      <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields">
                        <div className="formkit-field input-group mb-3">
                          <input className="formkit-input form-control" name="email_address" aria-label="Email Address" placeholder="Email Address" required type="email" />
                        </div>
                        <button data-element="submit" className="formkit-submit formkit-submit btn btn-primary" >
                          <div className="formkit-spinner">
                            <div>
                            </div>
                            <div>
                            </div>
                            <div>
                            </div>
                          </div>
                          <span className="">Subscribe</span>
                        </button>
                      </div>
                      <div className="formkit-guarantee" data-element="guarantee">
                        <p>Unsubscribe at any time.</p>
                      </div>
                      <div className="formkit-powered-by-convertkit-container">
                        <a href="https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic" data-element="powered-by" className="formkit-powered-by-convertkit" data-variant="dark" target="_blank" rel="nofollow">Built with ConvertKit</a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <footer className="pt-4 bg-secondary">
              <div className="row text-light">
                <div className="col-6 col-md">
                  <h5>About</h5>
                  <ul className="list-unstyled text-small">
                    <li><a className="text-light text-decoration-none" href="/privacy-policy.html">Privacy Policy</a></li>
                  </ul>
                </div>
              </div>
              <div className="text-light self-align-center">&copy; Partak 2024</div>
            </footer>
          </div>
        </div>
      </div>
    </>
  )
}
