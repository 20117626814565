import React, { useState, useEffect } from 'react'
import CardCollection from './collection-items'
import { getSharedItems } from '../api/backend-api'
import { ItemList } from '../interfaces/common-types'
import { SearchItems } from '../utils/search'

export default function SharedItems({
  searchText
}: {
  searchText: string
}) {
  const [items, setItems] = useState<ItemList>()


  useEffect(() => {
    getSharedItems()
    .then((response) => {
      // console.log("Shared Items response: ", response.data)
      const sharedItems: ItemList = { items: response.data }
      setItems(sharedItems)
    })
    .catch((error) => console.log("Shared Items error: ", error.response.status))
  }, [])
  
  if(!items)
  {
    return(
      <div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      )
  }

  return (
    <div>
      <p className="text-left"><span className="font-weight-bold">The Shared Items page shows all the items you are currently borrowing or lending to someone on Partak.</span></p>
      <CardCollection 
        items={SearchItems(items, searchText)}
        searchText={searchText}
        homeFeed={false}
      />
    </div>
  )
}