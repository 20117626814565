import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom"
import {Tabs, Tab } from 'react-bootstrap'
import CardCollection from './collection-items'
import ProjectsPostsCardCollection from './collection-project-posts'
import ProfileInfo from './profile-info';
import UserProjects from './projects-user-projects'
import { UserProfile, ItemList, ProjectPostList } from '../interfaces/common-types'
import { getUserItemsRequest, getUserPosts } from '../api/backend-api'
import { SearchItems, SearchFilterProjectPosts } from '../utils/search'

import 'react-image-crop/dist/ReactCrop.css'
import './profile.css'
import ProfileCommonGroups from './profile-mutual-groups'

// const localJson = axios.create({
//   baseURL: "./user.json"
// })

export default function Profile({
  searchText,
  userId
}: {
  searchText: string,
  userId: number
}) {

  let providedUserId: number;
  const location = useLocation();
  const { state } = location;
  if (state && state.id !== undefined && state.id !== null) {
    providedUserId = state.id
  }
  else {
    providedUserId = userId
  }

  const [items, setItems] = useState<ItemList>();
  const [key, setKey] = useState<string>('profile');
  const [currentProfile] = useState<UserProfile>()
  const [posts, setPosts] = useState<ProjectPostList>()

  const profileTabSelected = (tabSelected: string) => {
    setKey(tabSelected)
    if(tabSelected === "items") {
      getUserItems();
    }
    else if(tabSelected === "profile") {
      // not bothering to update profile on just tab selections,
      // so do nothing.
    }
    else if(tabSelected === "posts") {
      refreshUserPosts();
    }
  }

  const getUserItems = () => {
    const id: number =  currentProfile ? currentProfile.id : providedUserId
    getUserItemsRequest(id)
      .then((response) => {
        const itemsFeed: ItemList = { items: response.data }
        setItems(itemsFeed)
      })
      .catch((error) => console.log("getting community items list error: ", error.response.status))
  }

  useEffect(() => {
    const id: number =  currentProfile ? currentProfile.id : providedUserId
    getUserPosts(id)
    .then((response) => {
      // console.log("Posts feed Posts response: ", response.data)
      const postsFeed: ProjectPostList = { posts: response.data }
      setPosts(postsFeed)
    })
    .catch((error) => console.log("Posts feed Posts error: ", error.response.status))
  }, [currentProfile, providedUserId])

  const refreshUserPosts = () => {
    const id: number =  currentProfile ? currentProfile.id : providedUserId
    getUserPosts(id)
    .then((response) => {
      // console.log("Posts feed Posts response: ", response.data)
      const postsFeed: ProjectPostList = { posts: response.data }
      setPosts(postsFeed)
    })
    .catch((error) => console.log("Posts feed Posts error: ", error.response.status))
  }

  useEffect(() => {
    // console.log("one shot user items list")
    getUserItemsRequest(providedUserId)
      .then((response) => {
        const itemsFeed: ItemList = { items: response.data }
        setItems(itemsFeed)
      })
      .catch((error) => console.log("getting community items list error: ", error.response.status))
  }, [providedUserId])

  const userItems = () => {
    if(items)
    {
      if(key === "items") {
        return (
          <div>
            {items.items.length > 0 ? 
              <CardCollection 
                items={SearchItems(items, searchText)}
                searchText={searchText}
                homeFeed={true}
              />
            :
            <h4 className="text-left">No Items</h4>
            }
          </div>
        )
      }
    }
    else {
      return (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )
    }
  }

  return (
    <div>
      <h3 className="text-left">{currentProfile ? <>{currentProfile.first_name} {currentProfile.last_name}'s</> : <></>} Profile</h3>
      <Tabs 
        className="font-weight-bold" 
        activeKey={key}
        onSelect={(k) => {if(k) {profileTabSelected(k)}}}
      >
        <Tab eventKey="posts" title="Posts">{posts ? <ProjectsPostsCardCollection posts={SearchFilterProjectPosts(posts, searchText)} refresh={() => refreshUserPosts()}/> : <div>No Posts</div>}</Tab>
        <Tab eventKey="projects" title="Projects"><UserProjects userId={providedUserId} searchText={searchText}/></Tab>
        <Tab eventKey="items" title="Items">{userItems()}</Tab>
        <Tab eventKey="profile" title="Profile">
          <ProfileInfo userId={providedUserId}/>
          <ProfileCommonGroups userId={providedUserId}/>
        </Tab>
      </Tabs>
    </div>
  )
}