import { useContext } from 'react'

import { UserProfileLite } from '../interfaces/common-types'
import { config } from '../interfaces/constants'
import { StreamContext } from '../contexts/streamContext';
import { useClient } from '../hooks/useClient'
import { streamToken } from '../api/backend-api'

export default function SetupChatClient({
  activeUserProfileLite
}: {
  activeUserProfileLite: UserProfileLite
}) {
  const {updateStreamChat} = useContext(StreamContext);

  const user = {
    id: activeUserProfileLite.id.toString()
  };
  // Put this here so it is always active and the stream connection doesn't close whenever logged it.
  const userToken = streamToken();
  const initChatClient = useClient({ apiKey: config.STREAM_KEY, userData: user, tokenOrProvider: userToken })
  if(initChatClient && updateStreamChat) {
    updateStreamChat(initChatClient)
  }

  return (
    <></>
  )
}