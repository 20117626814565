import { useState, useEffect } from 'react'
import { ProjectPostList} from '../interfaces/common-types'
import { getProjectPostsFeed } from '../api/backend-api'
import ProjectsPostsCardCollection from './collection-project-posts'
import {SearchFilterProjectPosts} from '../utils/search'

export default function ProjectPostsFeed({
  searchText
}: {
  searchText: string
}) {

  const [posts, setPosts] = useState<ProjectPostList>()

  useEffect(() => {
    getProjectPostsFeed()
    .then((response) => {
      // console.log("Posts feed Posts response: ", response.data)
      const postsFeed: ProjectPostList = { posts: response.data }
      setPosts(postsFeed)
    })
    .catch((error) => console.log("Posts feed Posts error: ", error.response.status))
  }, [])

  const refeshPostsFeed = () => {
    getProjectPostsFeed()
    .then((response) => {
      // console.log("Posts feed Posts response: ", response.data)
      const postsFeed: ProjectPostList = { posts: response.data }
      setPosts(postsFeed)
    })
    .catch((error) => console.log("Posts feed Posts error: ", error.response.status))
  }


  return(
    <div>
    { posts ? 
        <ProjectsPostsCardCollection 
          posts={SearchFilterProjectPosts(posts, searchText)}
          refresh={refeshPostsFeed}
        />
        : <></> 
    }
    </div>
  )
}