import { useState } from 'react'
import ValidateEmailRegex from '../utils/validation'
import { sendInvite } from '../api/backend-api'


export default function SendSignupLink() {
  const [email, setEmail] = useState<string>("")
  const [errorDisplay, setErrorDisplay] = useState<string>("")
  const [isEmailvalid, setIsEmailValid] = useState<boolean>(false)
  const [signupLinkSent, setSignupLinkSent] = useState<boolean>(false)
  const [errorResponse, setErrorResponse] = useState<string>("")
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
  const [communityId, setCommunityId] = useState<number>(0)

  const resetPage = () => {
    setEmail("");
    setErrorDisplay("");
    setIsEmailValid(false);
    setSignupLinkSent(false)
    setErrorResponse("")
  }

  const onSignupLinkFormSubmit = (event: any) => {
    event.preventDefault();
    setDisableSubmit(true);
    if (!isValidForm()){
      return
    }
    
    sendInvite(email.toLowerCase(), communityId)
      .then((response) => {
        console.log("reponse", response)
        setErrorResponse(response)
      })
      .catch((error) => {
        console.log("error", error.response.data)
        setErrorResponse(error.response.data)
        setDisableSubmit(false);
      })
  }

  const ValidateEmailAddress = (email: string) => {
    if(email.length === 0){
      setIsEmailValid(false);
      setDisableSubmit(true);
    }
    else{
      if (ValidateEmailRegex(email)) {
        setIsEmailValid(true);
        setDisableSubmit(false);
      }
      else {
        setIsEmailValid(false);
        setDisableSubmit(true);
      }
    }
    setEmail(email);
  }

  const isValidForm = () => {
    // Check for errors and providing error reason
    if(!isEmailvalid) {
      setErrorDisplay("Invalid Email");
      return (false);
    }
    else if (communityId < 0) {
      setErrorDisplay("Invalid Community Id");
      return (false);
    }

    // No form errors, return success
    setErrorDisplay("");
    setSignupLinkSent(true);
    return (true);
  }

  return (
    <>
    {!signupLinkSent ?
    <div className="padding_section container">
    <div className="row justify-content-center">
        <div className="col-md-5">
            <div className="card text-left">
                <div className="card-body">
                    <h1 className="text-start">Send Invite</h1>
                    <p className="text-danger">{errorDisplay ? errorDisplay : ""}</p>
                    <form onSubmit={onSignupLinkFormSubmit} method="post" className="d-block">
                      <div className="form-group">
                        <label htmlFor="signup_email">Email:</label>
                        <input 
                          className="form-control" 
                          onChange={(event)=>{setEmail(event.target.value)}}
                          onBlur={(event)=>{ValidateEmailAddress(event.target.value)}}
                          type="text" 
                          id="signup_email" 
                          name="signup_email"
                          aria-describedby="errorHelp"
                        />
                        <label htmlFor="community">Community:</label>
                        <input 
                          className="form-control" 
                          onChange={(event)=>{setCommunityId(parseInt(event.target.value, 10))}}
                          type="text" 
                          id="community" 
                          name="community"
                          aria-describedby="errorHelp"
                        />
                      </div>
                      <button type="submit" className="btn btn-success btn-block mt-2" disabled={disableSubmit}>Send Invite Link</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </div>
    :
    <div>
      <h2 className="my-3">{errorResponse}</h2>
      <button type="button" className="btn btn-success" onClick={resetPage}>Create another sign-up link</button>
    </div>
  }
  </>
  )
}