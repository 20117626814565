import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import AddPost from './add-post'
import { Project, ProjectPostList } from '../interfaces/common-types'
import { getProjectDetails } from '../api/backend-api'
import ProjectsPostsCardCollection from './collection-project-posts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import UserProfileLink from './user-profile-link'
import {SearchFilterProjectPosts} from '../utils/search'

import './project-details.css'

export default function ProjectDetailsPage({
    searchText
  }: {
    searchText: string
  }){
  const location = useLocation();
  const { state } = location;
  const [project, setProject]  = useState<Project>();
  const [projId] = useState<number>(state.projectId)
  const [showAddPostModal, setShowAddPostModal] = useState<boolean>(false);

  const toggleShowAddPostModal = () => {
    setShowAddPostModal(!showAddPostModal);
    UpdateProjectDetails();
  }
  const refreshInterval = 10000;
  // console.log("Project Id", projId)

  const UpdateProjectDetails = useCallback(() => {
    getProjectDetails(state.projectId)
    .then((response) => {
      // console.log("Project Details response: ", response.status)
      // console.log("Project Details Response", response)
      const currentProject: Project = response.data
      setProject(currentProject)
    })
    .catch((error) => console.log("Projects feed Projects error: ", error.response.status))
  }, [state.projectId])

  useEffect(() => {
    getProjectDetails(state.projectId)
    .then((response) => {
      // console.log("Project Details response: ", response.status)
      // console.log("Project Details Response", response)
      const currentProject: Project = response.data
      setProject(currentProject)
    })
    .catch((error) => console.log("Projects feed Projects error: ", error.response.status))
    const updateInterval = setInterval(UpdateProjectDetails, refreshInterval)
    
    return () => clearInterval(updateInterval)
  }, [state.projectId, UpdateProjectDetails])

  if(!project)
  {
    return(
      <div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      )
  }

  const temp_datetime = new Date(project.created_datetime)
  const local_date_time = temp_datetime.toLocaleString()
  // console.log("proj details, posts", project.posts)

  return(
    <div>
      <h1 className="text-left">Project</h1>
      <p className="text-left font-weight-bold">The Projects page shows all the posts you have added to this project.</p>
      <div className="item-detail-card bg-white shadow-sm mb-3" key={project.name}>            
        <div className="card-body p-0">
          <div className="row">
            <h3 className="font-weight-bold text-left mt-3 ml-3">{project.name}</h3>
            <div className="text-left px-3">
              <div className="pl-3">
              <hr />
              <table className="table table-sm table-borderless font-weight-bold">
                <tbody>
                  <tr>
                    <th className="text-nowrap" scope="row">Project Creator:</th>
                    <td className="text-right pr-3"><UserProfileLink userProfileInfo={project.project_creator}></UserProfileLink></td>
                  </tr>
                  <tr>
                    <th className="text-nowrap" scope="row">Description:</th>
                    <td className="text-right pr-3">{project.description}</td>
                  </tr>
                  <tr>
                    <th className="text-nowrap" scope="row">Date of Creation:</th>
                    <td className="text-right pr-3">{local_date_time}</td>
                  </tr>
                </tbody>
              </table>
              </div>
              <button type="button" onClick={toggleShowAddPostModal} className="btn btn-success float-right mb-3 mr-3" data-toggle="modal" data-target="#borrowRequestModal"><div className="icon"><FontAwesomeIcon icon={faCirclePlus} /></div>Add Post</button>
            </div>
          </div>
        </div>
      </div>
      { project.posts ? 
        <ProjectsPostsCardCollection 
          posts={SearchFilterProjectPosts({posts: project.posts} as ProjectPostList, searchText)}
          refresh={UpdateProjectDetails}
        />
        : <></> 
      }
      <div>
        
        <Modal show={showAddPostModal} onHide={toggleShowAddPostModal} >
          <Modal.Header closeButton>
            <Modal.Title>Post Project Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddPost newPost={true} projectId={projId} modal={true} closeModal={toggleShowAddPostModal}/>
          </Modal.Body>
        </Modal>
        </div>
    </div>
  )
  }