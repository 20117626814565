import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { getNotificationsRequest, notificationWasRead } from '../api/backend-api'
import { ListOfNotifications, Notification } from '../interfaces/common-types'

export default function Notifications() {
  const [notificationList, setNotificationList] = useState<ListOfNotifications>({notifications: []})
  const [loading, setLoading] = useState<boolean>(true)
  const unreadBackgroundColor = {backgroundColor: "#FFF9DC"}
  const readBackgroundColor = {backgroundColor: "#FFFFFF"}
  useEffect(() => {
    getNotificationsRequest()
      .then((response) => {
        console.log("search for users response: ", response.status)
        const returnedNotificationsList: ListOfNotifications = { notifications: response.data }
        setNotificationList(returnedNotificationsList)
        setLoading(false)
      })
      .catch((error) => {
        console.log("getting community items list error: ", error.response.status)
        setNotificationList({notifications: []})
        setLoading(false)
      })
  }, [])

  const onNotificationClick = (id: number) => {
    notificationWasRead(id)
      .then((response) => {
        console.log("notification was read update response: ", response.status)
      })
      .catch((error) => {
        console.log("notification was read response error: ", error.response.status)
      })
  }

  if(!loading) {
    return (
      <div>
        <h3 className="text-left">Alerts</h3>
        <p className="text-left font-weight-bold">The Alerts page summarizes all notifications, such as a new <i>Borrow Request</i>.</p>
        <div className="card bg-light" style={{maxHeight: 'none'}}>
          <ul className="list-group list-group-flush">
          {notificationList.notifications.length > 0 ? notificationList.notifications.map((notification: Notification) => (
              <li style={notification.unread_status ? unreadBackgroundColor : readBackgroundColor} className="list-group-item">
                  <NavLink to={{pathname: notification.path}} state={{ id: notification.state_data}} className="text-dark text-decoration-none text-left" onClick={() => onNotificationClick(notification.id)}><div>{notification.text}</div></NavLink>
              </li>
          ))
          : 
          <h4 className="text-left">No Notifications</h4> 
          }
          </ul>
        </div>
      </div>
    )
  }
  else {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    )
  }

}