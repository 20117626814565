import React, { useState } from 'react'
import { NavLink } from "react-router-dom"
import Pagination from './pagination'

import UserProfileLink from './user-profile-link'
import { ProjectList, Project } from '../interfaces/common-types'

import './card-collection.css'

export default function ProjectsCardCollection({
  searchText,
  projects
}: {
  searchText: string,
  projects: ProjectList
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(5)

  const numberOfProjects = projects.projects.length
  const numberOfPages = Math.ceil(numberOfProjects / recordsPerPage)
  const indexOfLastRecord = currentPage * recordsPerPage
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage  
  const currentRecords = projects.projects.slice(indexOfFirstRecord, indexOfLastRecord)


  const normalCard = (project: Project) => {
    return(
      <div className="card-body p-0">
        <div className="card-header">
          <h5 className="font-weight-bold text-dark text-left">{project.name}</h5>
        </div>
        <div className="row m-1">
          <div className="text-left word-break card-text">
            <table className="table table-sm table-borderless font-weight-bold">
              <tbody>
                <tr>
                  <th scope="row">Description:</th>
                  <td>{project.description}</td>
                </tr>
                <tr>
                  <th scope="row">Project Creator:</th>
                  <td>{project.project_creator ? <UserProfileLink userProfileInfo={project.project_creator}></UserProfileLink> : ""}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="py-2">
      <Pagination
        nPages={numberOfPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {currentRecords.length > 0 ? currentRecords.map((project: Project) => (
        
        <div className="card bg-white shadow mb-3" key={project.name}>
          <NavLink to={{pathname: '/project_details'}} state={{ projectId: project.id}} className="text-decoration-none">
            {normalCard(project)}
          </NavLink>
        </div>
        
      )): 
      <h4 className="text-left">No Projects found</h4> }
      <Pagination
        nPages={numberOfPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div> 
  )

}