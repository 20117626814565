import React, {useState, useContext} from 'react'
import { useNavigate } from "react-router-dom"
import jwt_decode from 'jwt-decode'
import axios, {AxiosResponse} from 'axios'

import { UserContext } from '../contexts/userContext';
import { loginRequest, refreshToken } from '../api/backend-api';

type AccessToken = {
  exp: number,
  iat: number,
  jti: string,
  token_type: string,
  user_id: number
}

export default function Login() {
  const {userProfile, updateUserProfile} = useContext(UserContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errorDisplay, setErrorDisplay] = useState("")
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const navigateToRoot = useNavigate()

  const calculateTokenTimeout = (token: string): number => {
    const accessToken: AccessToken = jwt_decode(token);
    const timeoutDate = new Date(accessToken.exp * 1000);
    const currentTime = new Date();
    const deltaTime = Math.abs(timeoutDate.getTime() - currentTime.getTime());
    return deltaTime;
  }

  const refreshAccessToken = (): [boolean, number] => {
    let returnSuccess: boolean = false;
    let tokenTimeoutValue: number = 0;

    refreshToken()
      .then((response: AxiosResponse) => {
        if(response.status === axios.HttpStatusCode.Ok)
        {
          tokenTimeoutValue = calculateTokenTimeout(response.data.access);
          returnSuccess = true;
        }
      })
      return [returnSuccess, tokenTimeoutValue]
  }

  const onLoginFormSubmit = (event: any) => {
    event.preventDefault();
    setDisableSubmit(true)
    if (!isValidForm()){
      console.log("form invalid")
      setDisableSubmit(false)
      return
    }
    loginRequest(email.toLowerCase(), password)
      .then((data: any)=>{
        console.log("Login Request data: ", data)
        const tokenTimeout = calculateTokenTimeout(data.access)

        let refreshInterval = setTimeout(function refreshAccessTokenTimeout() {
          const [refreshResult, newTokenTimeout] = refreshAccessToken();
          if (refreshResult)
          {
            refreshInterval = setTimeout(refreshAccessTokenTimeout, newTokenTimeout);
          }
        }, tokenTimeout)
        
        if(updateUserProfile){
          updateUserProfile({...userProfile, logged_in: true, email: email.toLowerCase()});
          navigateToRoot('/')
        }

        return () => clearTimeout(refreshInterval)
        // history.push(getRouteAfterLogin());
      })
      .catch((error: any)=> {
        setErrorDisplay(error.response.data.detail)
        console.log("Login Error: ", error)
        setDisableSubmit(false)
      })
  }

  const isValidForm = () => {
    setErrorDisplay("")
    if (email === "" || password === ""){
      setErrorDisplay("email and password can't be empty. try user: test, password: test")
      return false;
    }
    return true;
  }

  return (
    <div className="row pt-3">
        <div className="col-md-5">
            <div className="card shadow text-left border-light">
                <div className="card-body">
                    <h1 className="text-start">Login</h1>
                    <p className="text-danger">{errorDisplay ? errorDisplay : ""}</p>
                    <form onSubmit={onLoginFormSubmit} method="post" className="d-block">
                      <div className="form-group">
                        <label htmlFor="login_email"><strong>Email:</strong></label>
                        <input 
                          className="form-control" 
                          onChange={(event)=>{setEmail(event.target.value)}}
                          type="text" 
                          id="login_email" 
                          name="login_email"
                          aria-describedby="errorHelp"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="login_password"><strong>Password:</strong></label>
                        <input 
                          className="form-control" 
                          onChange={(event)=>{setPassword(event.target.value)}}
                          type="password" 
                          id="login_password"
                          name="login_password"
                        ></input>
                      </div>
                      <button type="submit" className="btn btn-success btn-block mt-2" disabled={disableSubmit}>Log In</button>
                    </form>
                </div>
                <div className="ml-3 mb-2">
                    <p className="m-1">Forgot password? <a href="/password_reset">Reset password</a></p>
                    {/* <p className="d-inline m-1">No account?</p> */}
                    {/* <a href="/signup" className="d-inline">Sign up<br></br></a> */}

                </div>
            </div>
            
        </div>
    </div>

  )
}