import React, {useState, useEffect } from 'react'
import HomeFeedCardCollection from './collection-home-feed'
import { getHomeFeed, inSearchOfRequest } from '../api/backend-api'
import {AllPostTypeList} from '../interfaces/common-types'
import {SearchFilterPosts} from '../utils/search'
import Modal from "react-bootstrap/Modal"

import './home.css'

export default function Home({
  searchText
}: {
  searchText: string
}) {
  const [posts, setPosts] = useState<AllPostTypeList>()
  const [showIsoModal, setShowIsoModal] = useState<boolean>(false)
  const [requestMessage, setRequestMessage] = useState<string>('')
  const [showSentModal, setShowSentModal] = useState<boolean>(false)

  const toggleShowIsoModal = () => {
    setShowIsoModal(!showIsoModal);
  }

  const sendIsoMessage = () => {
    inSearchOfRequest(requestMessage);
    toggleShowIsoModal();
    toggleShowSentModal();
  }

  const toggleShowSentModal = () => {
    setShowSentModal(!showSentModal);
  }

  useEffect(() => {
    getHomeFeed()
    .then((response) => {
      console.log("Home feed response: ", response.data)
      const posts: AllPostTypeList = { posts:response.data }
      setPosts(posts)
    })
    .catch((error) => console.log("Home feed Items error: ", error.response.status))
  }, [])

  const refeshHomeFeed = () => {
    getHomeFeed()
    .then((response) => {
      console.log("Home feed response: ", response.data)
      const posts: AllPostTypeList = { posts:response.data }
      setPosts(posts)
    })
    .catch((error) => console.log("Home feed Items error: ", error.response.status))
  }

  
  if(!posts)
  {
    return(
      <div>
        <h3 className="text-left">Home</h3>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      )
  }

  return (
    <div>
      <h3 className="text-left">Home</h3>
      <p className="text-left font-weight-bold">The Home feed contains the items that your friends and communities have added to Partak.</p>
      <HomeFeedCardCollection 
        posts={SearchFilterPosts(posts, searchText)} refreshFeed={refeshHomeFeed}
      />
      <div className="container pb-3">
        <div className="row">
          <div className="col-lg-8">
            <p className="text-left">Not finding what your looking for? <br />Let your community know what what you are searching for.</p>
          </div>
          <div className="col-lg-4">
            <button className="btn btn-success" onClick={toggleShowIsoModal}>In Search Of: Item</button>
          </div>
        </div>
      </div>
      <Modal show={showIsoModal} onHide={toggleShowIsoModal} >
        <Modal.Header closeButton>
          <Modal.Title>Send In Search Of Item Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form">
            <div className="form-row text-left mb-2">
            <p className="text-left">Reach out to your friends to see if they have the item you are looking for.</p>
            <p>By typing the item you are looking for in the box below and pressing send, a chat message will be sent to everyone in the groups you belong to.</p>
            <p className="text-left">If someone has the item, they can respond to your message. Then add the item for you to borrow.</p>
              <label className="col">In Search Of:</label>
              <textarea
              onChange={(event)=>{setRequestMessage(event.target.value)}} 
              className="form-control"
              id="requestMessageBox" 
              rows={1}
              value={requestMessage} 
              placeholder="Item Description" 
              >
                </textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={toggleShowIsoModal}>Close</button>
          <button type="button" className="btn btn-success" onClick={sendIsoMessage}>Send</button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSentModal} onHide={toggleShowSentModal} >
        <Modal.Header closeButton>
          <Modal.Title>Message Sent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your In Search Of message has been sent to your communities.</p>
          <p>Check your Chat for responses.</p>
        </Modal.Body>
      </Modal>
    </div>
  )
}