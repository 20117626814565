import React, {useState, useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'

import CardCollection from './collection-items'
import { getMyItems } from '../api/backend-api'
import { ItemList } from '../interfaces/common-types'
import { SearchItems } from '../utils/search'

import './nav-sidebar.css'

export default function UserItems({
  searchText
}: {
  searchText: string
}) {
  const [items, setItems] = useState<ItemList>()

  useEffect(() => {
    getMyItems()
    .then((response) => {
      const formattedJsonResponse: ItemList = { items: response.data }
      console.log(formattedJsonResponse)
      setItems(formattedJsonResponse)
    })
    .catch((error) => console.log(error))
  }, [])
  
  if(!items)
  {
    return(
      <div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      )
  }

  return (
    <div>
      <p className="text-left font-weight-bold">The My Items page shows all the items you have added to Partak.</p>
      <NavLink 
        to='/add_item'
        className='navbar-additem-inactive'
      >
        <div className="icon"><FontAwesomeIcon icon={faCirclePlus} /></div>
        <div className="name">Add Item</div>
      </NavLink>
      <CardCollection 
        items={SearchItems(items, searchText)}
        searchText={searchText}
        homeFeed={false}
      />
    </div>
  )
}
