import React from 'react'

import './pagination.css'

export default function Pagination(
  {
    nPages,
    currentPage,
    setCurrentPage
  }: {
    nPages: number,
    currentPage: number,
    setCurrentPage: (setPage: number) => void   
  }) {
  
  // create an array of page numbers
  const pageNumbers = [...Array(nPages)].map((_, index) => index+1)

  const activePageItem = "page-item active"
  const disabledPageItem = "page-item disabled"
  const pageItem = "page-item"
  const pageLink = "page-link"

  const firstPage = () => {
    setCurrentPage(1)
  }
  const lastPage = () => {
    setCurrentPage(nPages)
  }
  const nextPage = () => {
    if(currentPage !== nPages) setCurrentPage(currentPage + 1)
  }
  const prevPage = () => {
    if(currentPage !== 1) setCurrentPage(currentPage - 1)
  }

  const listPages = () => {
    if (nPages > 5) {
      let startRange = 0;
      let endRange = nPages;
      let startEllipsis = false;
      let endEllipsis = false;

      if (currentPage < 6) {
        startRange = 0;
        endRange = 5;
        endEllipsis = true;
      }
      else if (currentPage > nPages - 5) {
        startRange = nPages - 5;
        endRange = nPages;
        startEllipsis = true;
      }
      else {
        startRange = currentPage - 3;
        endRange = currentPage + 2;
        startEllipsis = true;
        endEllipsis = true;
      }

      return (
        <>
        {startEllipsis ?
        <>
          <li key={996} className={pageItem}>
            <button className={pageLink} onClick={firstPage}>1</button>
          </li>
          <li key={997} className={disabledPageItem}>
            <button className={pageLink}>...</button>
          </li>
        </>
        :
          <></>
        }
        {pageNumbers.slice(startRange, endRange).map((pgNumber, index) => (
            <li key={index} className={currentPage===pgNumber ? activePageItem : pageItem}>
              <button className={pageLink} onClick={() => setCurrentPage(pgNumber)}>{pgNumber}</button>
            </li>
        ))}
        {endEllipsis ?
        <>
          <li key={998} className={disabledPageItem}>
            <button className={pageLink} >...</button>
          </li>
          <li key={999} className={pageItem}>
            <button className={pageLink} onClick={lastPage}>{nPages}</button>
          </li>
        </>
        :
          <></>
        }
        </>
      )
    }
    else {
      return (
        <>
        {pageNumbers.map((pgNumber, index) => (
          <li key={index} className={currentPage===pgNumber ? activePageItem : pageItem}>
            <button className={pageLink} onClick={() => setCurrentPage(pgNumber)}>{pgNumber}</button>
          </li>
        ))}
        </>
      )
    }
  }

  if(nPages > 0)
  {
    return (
      <nav aria-label="..." className="d-flex justify-content-center">
        <ul className="pagination">
          <li className={(currentPage===1) ? disabledPageItem : pageItem}>
            <button className={pageLink} onClick={prevPage}><i className="fas fa-angle-left"></i></button>
          </li>
          {listPages()}
          <li className={(currentPage===nPages) ? disabledPageItem : pageItem}>
            <button className={pageLink} onClick={nextPage}><i className="fas fa-angle-right"></i></button>
          </li>
        </ul>
      </nav>
    )
  }
  else
  {
    return(
      <div></div>
    )
  }
}