import React, {useState, useEffect, useContext} from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

import { UserContext } from '../contexts/userContext';
import ProfileInfo from "./profile-info";

import { searchForFriend, sendKarmaApi, getUserProfile } from '../api/backend-api'
import { UserProfileLite, UserProfile } from '../interfaces/common-types'

export default function SendKarma() {
  const [userSearch, setUserSearch] = useState<string>("");
  const [returnedUsers, setReturnedUsers] = useState<UserProfileLite[]>([]);
  const [value, setValue] = useState<UserProfileLite | null>(null);
  const [karmaAmount, setKarmaAmount] = useState<number>(0)
  const {userProfile, updateUserProfile} = useContext(UserContext);
  const [responseData, setResponseData] = useState<string>("")

  useEffect(() => {
    if(userSearch !== "") {

    searchForFriend(userSearch)
      .then((result) => {
        console.log("search for friend response: ", result.status)
        const returnedUserList: UserProfileLite[] = result.data
        console.log("returned user list: ", returnedUserList)
        setReturnedUsers(returnedUserList)
      })
      .catch((error) => {
        console.log("getting community items list error: ", error.response.status)
        setReturnedUsers([])
      })
    }
  }, [userSearch])

  const onSubmitSendKarma = (event: any) => {
    event.preventDefault();

    if(value) {
      sendKarmaApi(value.id, karmaAmount)
        .then((result) => {
          console.log("send karma response: ", result.status)
          setValue(null);
          setKarmaAmount(0);
          setResponseData(result.data)

          getUserProfile(userProfile.id)
            .then((response) => {
              const profile: UserProfile = response.data
              if(updateUserProfile) {
                updateUserProfile({ ...userProfile, ...profile})
              }})
          })
        .catch((error) => {
          console.log("error on send karma", error.response.status)
          setResponseData(error.response.data)
        })
    }
  }

  return (
    <div>
      <p className="text-left ml-1">Send Karma to someone as a thanks for their help.</p>
      <p className="font-weight-bold"><span style={{color: 'red'}}>{responseData}</span></p>
      <div className="card post-card bg-white shadow mb-3 rounded">
        <div className="card-body p-0">
          <div className="row">
            <Autocomplete
              id="combo-box-demo"
              getOptionLabel={(option) => 
                option.first_name + " " + option.last_name}
              options={returnedUsers}
              sx={{ width: 300 }}
              value={value}
              onChange={(event: any, newValue: UserProfileLite | null) => {
                setValue(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setUserSearch(newInputValue);
              }}
              renderInput={(params) => <TextField {...params} label="Search for User" />}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.first_name} {option.last_name}
                </Box>
              )}
            />
            
          </div>
          { value !== null && <ProfileInfo userId={value.id}/> }
          <form className="m-3" onSubmit={onSubmitSendKarma} method="post">
            <div className="row align-items-center">
              <div className="col">Amount to send:</div>
              <input 
                type="number" step={1} min={0} max={userProfile.coins} 
                className="form-control mx-1 col" 
                id="karma-count" 
                onChange={(e) => setKarmaAmount(e.target.valueAsNumber)}
                value={karmaAmount}
                required
              />
              <button type="submit" className="btn btn-success col" disabled={value===null}>Send</button>
            </div>
          </form>
        </div>
      </div> 
    </div>
  )
  
}