import { StreamChat } from "stream-chat";

export type Item = {
  id: number;
  name: string;
  cost: number;
  cost_unit: string;
  owner: UserProfileLite;
  description: string;
  status: string;
  borrower: UserProfileLite;
  images: ItemImage[],
  date_borrowed: string;
  due_date: string;
}

export type ItemList = {
  items: Item[];
}

export type ItemDetails = {
  id: number,
  name: string,
  description: string,
  cost: number,
  cost_unit: string,
  date_borrowed: string,
  due_date: string,
  owner: UserProfileLite,
  borrower: UserProfileLite,
  images: ItemImage[],
  status: string,
  exchange_method: string,
  request_comments: string
}

export const defaultItemDetails: ItemDetails = {
  id: 0,
  name: "",
  description: "",
  cost: 0,
  cost_unit: "Day",
  date_borrowed: "",
  due_date: "",
  owner: {} as UserProfileLite,
  borrower: {} as UserProfileLite,
  images: [],
  status: "",
  exchange_method: "",
  request_comments: ""
}

export type ItemImage = {
  id: number,
  image: string
}

export type Project = {
  id: number,
  name: string,
  description: string,
  project_creator: UserProfileLite,
  project_contributors: UserProfileLite[],
  created_datetime: Date,
  posts: ProjectPost[]
}

export type ProjectList = {
  projects: Project[];
}

export const defaultProjectDetails: Project = {
  id: 0,
  name: "",
  description: "",
  project_creator: {} as UserProfileLite,
  project_contributors: {} as UserProfileLite[],
  created_datetime: new Date(),
  posts: []
}

export type PostImage = {
  id: number,
  image: string
}

export type ProjectPost = {
  id: number,
  post_creator: UserProfileLite,
  text: string,
  created_datetime: Date,
  updated_datetime: Date,
  project: Project,
  images: PostImage[],
  comments: PostComment[]
}

export type Post = {
  id: number,
  post_creator: UserProfileLite,
  text: string,
  created_datetime: Date,
  updated_datetime: Date,
  images: PostImage[],
  comments: PostComment[]
}

export type ProjectPostList = {
  posts: ProjectPost[]
}

export const defaultProjectPost: ProjectPost = {
  id: 0,
  post_creator: {} as UserProfileLite,
  text: "",
  created_datetime: new Date(),
  updated_datetime: new Date(),
  project: {} as Project,
  images: [],
  comments: [] as PostComment[]
}

export type AllPostType = {
  post_type: string,
  data: Post | ProjectPost | Project | Item
}

export type AllPostTypeList = {
  posts: AllPostType[]
}

export type UserProfileLite = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  coins: number;
  profile_pic: string;
  logged_in: boolean;
}


export interface IUserContext {
  userProfile: UserProfile;
  updateUserProfile?: (updatedProfile: UserProfile) => void;
}

export interface IUnreadContext {
  unreadCount: number;
  updateUnreadCount?: (updateUnreadCountVal: number) => void;
}

export interface IStreamContext {
  unreadChatCount: number;
  updateUnreadChatCount?: (updateUnreadChatCountVal: number) => void;
  streamChat?: StreamChat;
  updateStreamChat?: (updateStreamChatVal: StreamChat) => void;
}

export type UserProfile = UserProfileLite & {
  id: number;
  email: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: number;
  is_staff: boolean;
  friend_status: string;
}

export const defaultUserProfile: UserProfile = {
  id: 0,
  first_name: "",
  last_name: "",
  coins: 0,
  profile_pic: "",
  logged_in: false,
  email: "",
  street_address: "",
  city: "",
  state: "",
  zip_code: 0,
  is_staff: false,
  friend_status: "not_friends"
}

export type UserList = {
  users: UserProfileLite[];
}

export const defaultUser = {
  userProfile: defaultUserProfile
}

export type Group = {
  id: number;
  group_name: string;
  group_owner: UserProfile;
  users: UserProfile[];
  description: string;
}

export type GroupList = {
  groups: Group[];
}

export type Notification = {
  id: number;
  user: UserProfileLite;
  unread_status: boolean;
  path: string;
  state_data: number;
  created_date: string;
  text: string;
}

export type ListOfNotifications = {
  notifications: Notification[]
}

export type CommentInfo = {
  comment_text: string,
  comment_button_disabled: boolean
}

export type PostComment = {
  id: number,
  post: number,
  commenter: UserProfileLite,
  text: string,
  created: Date,
  updated: Date,
}
