import React, { useState, useEffect, useContext, useRef } from 'react'
import { NavLink } from "react-router-dom"
import { Modal } from 'react-bootstrap'
import ReactCrop, { Crop, PercentCrop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop'
import axios from 'axios';
import { UserContext } from '../contexts/userContext';
import { UserProfile } from '../interfaces/common-types'
import { getUserProfile, sendAddUser, friendRequest, updateFriendRequest, unfriendRequest } from '../api/backend-api'

import 'react-image-crop/dist/ReactCrop.css'
import './profile.css'
import { useDebounceEffect, canvasPreview } from '../utils/image-handling';

// const localJson = axios.create({
//   baseURL: "./user.json"
// })

export default function ProfileInfo({
  userId
}: {
  userId: number
}) {

  const {userProfile, updateUserProfile} = useContext(UserContext)
  const [currentProfile, setCurrentProfile] = useState<UserProfile>()
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [imgSrc, setImgSrc] = useState('')
  const [showPicUploadModal, setShowPicUploadModal] = useState<boolean>(false);
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [refresh, setRefresh] = useState<boolean>(false)
  const aspect = 1

  const myProfile = () => {
    return (userProfile.id === userId)
  }

  const toggleShowPicUploadModal = () => {
    setImgSrc("")
    setShowPicUploadModal(!showPicUploadModal);
  }

  const toggleRefresh = () => {
    setRefresh(!refresh)
  }

  useEffect(() => {
    getUserProfile(userId)
    .then((response) => {
      const profile: UserProfile = response.data
      if(myProfile() && updateUserProfile) {
        updateUserProfile({ ...userProfile, ...profile})
      }
      setCurrentProfile(profile)
    })
    .catch((error) => console.log(error))
  }, [refresh, userId])
  
  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const fileList = event.target.files

    if(fileList && fileList.length > 0) {
      setCrop(undefined)
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const newimagesrc = reader.result?.toString()
        setImgSrc(newimagesrc || '')
    })
    reader.readAsDataURL(fileList[0])
    }
    return
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
      const { width, height } = e.currentTarget
      const newCrop = centerCrop(makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: '%',
          width: 90,
        },
        aspect,
        width,
        height
      ),
      width,
      height
      )

      setCrop(newCrop)
    }

  useDebounceEffect(
  async () => {
  if (
  completedCrop?.width &&
  completedCrop?.height &&
  imgRef.current &&
  previewCanvasRef.current
  ) {
  // We use canvasPreview as it's much faster than imgPreview.
  canvasPreview(
  imgRef.current,
  previewCanvasRef.current,
  completedCrop
        )
      }
    },
    100,
    [completedCrop],
  )

  async function onUpdatePicFormSubmit() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob((newImageBlob) => {
      if (!newImageBlob) {
        throw new Error('Failed to create blob')
      }
      const picFileName = userProfile.first_name + "_" + userProfile.last_name + "_profile_pic.jpeg"
      const imageFile = new File([newImageBlob], picFileName, {type: "image/jpeg" })

      if(userProfile && imageFile) {
        toggleShowPicUploadModal()
        sendAddUser(false, userProfile.first_name, userProfile.last_name, userProfile.email.toLowerCase(), userProfile.street_address, userProfile.city, userProfile.state, userProfile.zip_code.toString(), imageFile)
          .then((data: any)=>{
            if(data.status === axios.HttpStatusCode.Ok){
              toggleRefresh()
              // console.log("Profile pic changed")
            }
          })
          .catch((error: any)=> {
            console.log("Pic upload error: ", error)
          })
      }
    })
  }

  const onChange = (percentCrop: PercentCrop) => {
      setCrop(percentCrop)
  }

  const requestAddFriend = () => {
    if(currentProfile) {
      friendRequest(currentProfile.id)
        .then((response: any)=>{
          toggleRefresh()
          console.log(response.data)
        })
        .catch((error: any)=> {
          console.log("friend request error: ", error)
        })
    }
  }

  const acceptFriend = (decision: string) => {
    if(currentProfile) {
      updateFriendRequest(currentProfile.id, decision)
        .then((response: any)=>{
          toggleRefresh()
          console.log(response.data)
        })
        .catch((error: any)=> {
          console.log("friend request error: ", error)
        })
    }
  }

  const unfriend = () => {
    if(currentProfile) {
      unfriendRequest(currentProfile.id)
        .then((response: any)=>{
          toggleRefresh()
          console.log(response.data)
        })
        .catch((error: any)=> {
          console.log("unfriending error: ", error)
        })
    }
  }

  const friendStatus = () => {
    if(currentProfile?.friend_status === "not_friends") {
      return (
        <button className="btn btn-success float-right mb-3 mr-3" onClick={requestAddFriend}>Add Friend</button>
      )
    }
    else if(currentProfile?.friend_status === "pending") {
      return (
        <button className="btn btn-secondary float-right mb-3 mr-3" disabled>Pending</button>
      )
    }
    else if(currentProfile?.friend_status === "friends") {
      return (
        <div className="dropdown">
          <button className="btn btn-secondary float-right dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
            Friends
          </button>
          <div className="dropdown-menu">
            <a className="dropdown-item" onClick={unfriend}>Unfriend</a>
          </div>
        </div>
      )
    }
    else if(currentProfile?.friend_status === "accept_decline") {
      return (
        <>
          <button className="btn btn-success float-right mb-3 mr-3" onClick={() => acceptFriend("accept")}>Accept</button>
          <button className="btn btn-danger float-right mb-3 mr-3" onClick={() => acceptFriend("decline")}>Decline</button>
        </>
      )
    }
  }

  if(!currentProfile)
  {
    return(
      <div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      )
  }

  return (
    <div>
      <div className="card bg-white shadow-sm mb-3 profile-card">            
        <div className="card-body p-0">
          <div className="row text-nowrap ml-2 mt-2">
            <div className="col-sm-4">
              <img className="mh-100 img-thumbnail rounded-circle profile-image" src={currentProfile.profile_pic} alt="profile"/>
              {myProfile() ? 
                <div className="card-img-overlay d-flex flex-column justify-content-center semi-transparent" >
                  <button className="btn btn-outline-dark w-10 align-self-center" onClick={toggleShowPicUploadModal} data-toggle="modal" data-target="#changeprofilepic"><i className="fas fa-camera fa-2xl"></i></button>
                </div>
              :
              <></>}
            </div>
            <div className="col-sm-8 text-left word-break align-self-center">
              <h2 className="font-weight-bold user-name">{currentProfile.first_name} {currentProfile.last_name}</h2>
            </div>
          </div>
          <div className="row no-gutters text-left p-3">
              <table className="table table-sm table-borderless font-weight-bold">
              <tbody>
                <tr>
                  <th scope="row">Email:</th>
                  <td className="text-left">{currentProfile.email}</td>
                </tr>
                <tr>
                  <th scope="row">Address:</th>
                  <td className="text-left">{currentProfile.street_address}, {currentProfile.city} {currentProfile.state} {currentProfile.zip_code.toString()}</td>
                </tr>
              </tbody>
              </table>
              {myProfile() ?
              <div>
                <hr />
                <NavLink to={{pathname: '/signup'}} state={{ userProfile: userProfile}} className="btn btn-success float-right mb-3 mr-3">Edit Profile</NavLink>
              </div>
              :
                <div>
                  <hr />
                  {friendStatus()}
                </div>
              }
          </div>
        </div>
        </div>
        {myProfile() ? 
          <Modal 
            show={showPicUploadModal} 
            onHide={toggleShowPicUploadModal} 
            backdrop="static"
            keyboard={false} >
            <Modal.Header closeButton>
              <Modal.Title>Upload Picture</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form className="form">
              <div className="form-group text-left">
                <label htmlFor="itemImage">Upload Image</label>
                <input type="file" className="form-control-file" id="itemImage" 
                  accept="image/png, image/jpeg, image/jpg" onChange={handleFileChange} required/>
              </div>
            </form>
            {!!imgSrc && (
              <>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => onChange(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={1}
              >
                <img
                  ref={imgRef}
                  alt="Crop profile"
                  src={imgSrc}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
              <canvas 
                ref={previewCanvasRef}
                id="myCanvas"
                width={0}
                height={0}
                style={{border: "1px solid #000000", display: "none" }}
              ></canvas>
              </>
            )}
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary" onClick={toggleShowPicUploadModal}>Cancel</button>
              <button type="button" className="btn btn-success" onClick={onUpdatePicFormSubmit}>Submit</button>
            </Modal.Footer>
          </Modal>
        :
          <></>
        }
    </div>
  )
}