import { useState, useEffect, useContext } from 'react'
import {Tabs, Tab } from 'react-bootstrap'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

import ProfileInfo from './profile-info';
import UserProfileLink from './user-profile-link'
import CardCollection from './collection-items'
import { friendsListRequest, friendsItemsRequest, searchForUser } from '../api/backend-api'
import { ItemList, UserList, UserProfileLite } from '../interfaces/common-types'
import { SearchItems } from '../utils/search'


import './community.css'

export default function FriendsPage({
  searchText
}: {
  searchText: string
}) {
  const [items, setItems] = useState<ItemList>();
  const [friendsList, setFriendsList] = useState<UserList>();
  const [key, setKey] = useState<string>('items');

  const [userSearch, setUserSearch] = useState<string>("");
  const [returnedUsers, setReturnedUsers] = useState<UserProfileLite[]>([]);
  const [value, setValue] = useState<UserProfileLite | null>(null);
  const [responseData, setResponseData] = useState<string>("")

  useEffect(() => {
    if(userSearch !== "") {

      searchForUser(userSearch)
        .then((result) => {
          console.log("search for user response: ", result.status)
          const returnedUserList: UserProfileLite[] = result.data
          console.log("returned user list: ", returnedUserList)
          setReturnedUsers(returnedUserList)
        })
        .catch((error) => {
          console.log("search for user error: ", error.response.status)
          setReturnedUsers([])
        })
    }
  }, [userSearch])

  const friendsTabSelected = (tabSelected: string) => {
    setKey(tabSelected)
    if(tabSelected === "items") {
      getFriendsItems();
    }
    else if(tabSelected === "friends") {
      getFriends();
    }
  }

  const getFriendsItems = () => {
    friendsItemsRequest()
      .then((response) => {
        // console.log("getting friends items list response: ", response.status)
        const itemsFeed: ItemList = { items: response.data }
        setItems(itemsFeed)
      })
      .catch((error) => console.log("getting friends items list error: ", error.response.status))
  }

  useEffect(() => {
    console.log("one shot friends items list")
    friendsItemsRequest()
      .then((response) => {
        // console.log("getting friends items list response: ", response.status)
        const itemsFeed: ItemList = { items: response.data }
        setItems(itemsFeed)
      })
      .catch((error) => console.log("getting friends items list error: ", error.response.status))
  }, [])

  const getFriends = () => {
    console.log("getting friends")
    friendsListRequest()
      .then((response) => {
        console.log("getting friends response: ", response.data)
        const newFriendsList: UserList = { users: response.data }
        setFriendsList(newFriendsList)
      })
      .catch((error) => console.log("getting friends error: ", error.response.status))
  }

  const displayFriendsItems = () => {
    if(items)
    {
      if(key === "items") {
        return (
          <div>
            {items.items.length > 0 ? 
              <CardCollection 
                items={SearchItems(items, searchText)}
                searchText={searchText}
                homeFeed={true}
              />
            :
            <h4 className="text-left">No Friends Items</h4>
            }
          </div>
        )
      }
    }
    else {
      return (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )
    }
  }

  const displayFriends = () => {
    if(friendsList) {
      console.log("list friends")
      return (
      <>
      <div className="card bg-light" style={{maxHeight: 'none'}}>
        <ul className="list-group list-group-flush">
        {friendsList.users.length > 0 ? friendsList.users.map((friend: UserProfileLite) => (
            <li className="list-group-item" key={friend.id}>
              <div className="float-left">
                <UserProfileLink userProfileInfo={friend}></UserProfileLink>
              </div>
            </li>
        ))
        : 
        <h4 className="text-left">You have not added any friends yet</h4> 
        }
        </ul>
      </div>
      <div className="card post-card bg-white shadow my-3 rounded">
      <h4 className="text-left pt-2 pl-2">Add a friend</h4> 
      <p className="font-weight-bold"><span style={{color: 'red'}}>{responseData}</span></p>
        <div className="card-body p-0">
          <div className="row">
            <Autocomplete
              id="combo-box-demo"
              getOptionLabel={(option) => 
                option.first_name + " " + option.last_name}
              options={returnedUsers}
              sx={{ width: 300 }}
              value={value}
              onChange={(event: any, newValue: UserProfileLite | null) => {
                setValue(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                setUserSearch(newInputValue);
              }}
              renderInput={(params) => <TextField {...params} label="Search for User" />}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.first_name} {option.last_name}
                </Box>
              )}
            />
            { value !== null && <ProfileInfo userId={value.id}/> }
          </div>
        </div>
      </div>
      </>
      )
    }
    else {
      return (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )
    }
  }

  return (
    <div>
      <h3 className="text-left ml-1">Communities -{'>'} Friends</h3>
      <Tabs 
        className="font-weight-bold" 
        activeKey={key}
        onSelect={(k) => {if(k) {friendsTabSelected(k)}}}
        fill
      >
        <Tab eventKey="items" title="Items">{displayFriendsItems()}</Tab>
        <Tab eventKey="friends" title="Friends">{displayFriends()}</Tab>
      </Tabs>
    </div>
  )
}