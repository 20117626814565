import { useState, useEffect, useContext } from 'react';
import { StreamChat, ConnectionOpen } from 'stream-chat';

import { StreamContext } from '../contexts/streamContext';

export const useClient = (
  { apiKey, userData, tokenOrProvider
  } : {
    apiKey: any,
    userData: any,
    tokenOrProvider: any
  }) => {
  const [chatClient, setChatClient] = useState<StreamChat | null>();
  const {updateUnreadChatCount} = useContext(StreamContext)


  useEffect(() => {
    const client = StreamChat.getInstance(apiKey);
    // prevents application from setting stale client (user changed, for example)
    let didUserConnectInterrupt = false;
    console.log("userData for stream: ", userData);
    const connectionPromise = client.connectUser(userData, tokenOrProvider)
      .then((response: ConnectionOpen | void) => {
        if (!didUserConnectInterrupt && response !== null && updateUnreadChatCount) {
          const connectionOpenResponse: ConnectionOpen = response as ConnectionOpen
          console.log("Stream Connection Open response: ", connectionOpenResponse);
          console.log("User Unread message count: ", connectionOpenResponse.me?.total_unread_count);
          if(connectionOpenResponse.me) {
            const tempUnreadChatCount: number = (connectionOpenResponse.me.total_unread_count !== null) ? (connectionOpenResponse.me.total_unread_count as number) : 0;
            updateUnreadChatCount(tempUnreadChatCount);
          }
          // setTotalUnreadCount(response)
          setChatClient(client);
        }
      })

    return () => {
      didUserConnectInterrupt = true;
      setChatClient(null);
      // wait for connection to finish before initiating closing sequence
      connectionPromise
        .then(() => client.disconnectUser())
        .then(() => {
          console.log('connection closed');
        });
    };
  }, [apiKey, userData.id, tokenOrProvider]);

  return chatClient;
};