import React, { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom"
import Pagination from './pagination'

import UserProfileLink from './user-profile-link'
import { ItemList, Item } from '../interfaces/common-types'
import { formattedDateToString } from '../utils/formatting'

import './card-collection.css'

export default function CardCollection({
  searchText,
  items,
  homeFeed
}: {
  searchText: string,
  items: ItemList,
  homeFeed: boolean
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(5)

  const numberOfItems = items.items.length
  const numberOfPages = Math.ceil(numberOfItems / recordsPerPage)
  const indexOfLastRecord = currentPage * recordsPerPage
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage  
  const currentRecords = items.items.slice(indexOfFirstRecord, indexOfLastRecord)

  useEffect(() => (
    setCurrentPage(1)
  ), [searchText])

  const getItemImage = (item: Item) => {
    if(item.images.length >= 1) {
      // console.log("item image", item.images[0].image)
      return (
        item.images[0].image
      )
    }
    else {
      return("")
    }
  }

  const normalCard = (item: Item) => {
    return(
      <div className="card-body p-0">
        <div className="card-header">
          <div className="row m-1">
            <h5 className="font-weight-bold text-dark text-left">{item.name}</h5>
          </div>
        </div>
        <div className="row">
          <div className="col mh-100 item-image d-flex justify-content-end">
            <img className="item-card-image float-right" src={getItemImage(item)} alt={item.name}/>
          </div>
          <div className="col-sm-8 text-left word-break card-text">
            <table className="table table-sm table-borderless font-weight-bold">
              <tbody>
                <tr>
                  <th scope="row">Cost:</th>
                  <td className="text-left">{item.cost} per {item.cost_unit}</td>
                </tr>
                <tr>
                  <th scope="row">Status:</th>
                  <td>{item.status}</td>
                </tr>
                <tr>
                  <th scope="row">Borrower:</th> 
                  <td>{item.borrower ? <UserProfileLink userProfileInfo={item.borrower}></UserProfileLink> : ""}</td>
                </tr>
                <tr>
                  <th scope="row">Due:</th>
                  <td>{item.due_date ? formattedDateToString(item.due_date) : ""}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

const homeFeedCard = (item: Item) => {
  return(
    <div className="card-body p-0">
      <div className="card-header">
          <div className="row m-1">
            <div className="col-8 font-weight-bold text-dark text-left">{item.name}</div>
            <div className="col-4 font-weight-bold text-dark text-right"><i className="fas fa-coins"></i> {item.cost} per {item.cost_unit}</div>
          </div>
      </div>
      <div className="row">
        <div className="col mh-100 item-image d-flex justify-content-end">
          <img className="item-card-image float-right" src={getItemImage(item)} alt={item.name}/>
        </div>
        <div className="col-sm-8 text-left word-break card-text">
          <table className="table table-sm table-borderless">
            <tbody>
              <tr>
                <th scope="row">Owner:</th>
                <td className="text-dark"><UserProfileLink userProfileInfo={item.owner}></UserProfileLink></td>
              </tr>
              <tr>
                <th scope="row">Status:</th>
                <td>{item.status}</td>
              </tr>
              <tr>
                <th scope="row">Description:</th>
                <td className="text-dark">{item.description}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

  return (
    <div className="py-2">
      <Pagination
        nPages={numberOfPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {currentRecords.length > 0 ? currentRecords.map((item: Item) => (
        
        <div className="card bg-white shadow mb-3 rounded" key={item.name}>
          <NavLink to={{pathname: '/item_details'}} state={{ id: item.id}} className="text-decoration-none">
            {homeFeed ? homeFeedCard(item) : normalCard(item)}
          </NavLink>
        </div>
        
      )): 
      <h4 className="text-left">No Items found</h4> }
      <Pagination
        nPages={numberOfPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div> 
  )

}