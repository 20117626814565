import React, {useState, useEffect, useMemo, useCallback} from 'react'
import {Routes, Route} from 'react-router-dom'

import TopBar from './components/top-bar'
import NavList from './components/nav-sidebar'
import Home from './components/home'
import Messages from './components/messages'
import Items from './components/items'
import AddItem from './components/add-item'
import Profile from './components/profile-page'
import PageNotFound from './components/page_not_found'
import LandingPage from './components/landing-page'
import Login from './components/login'
import Signup from './components/signup'
import PasswordReset from './components/password-reset'
import PasswordResetConfirm from './components/password-reset-confirm'
import ItemDetailsPage from './components/item-details'
import AddModifyGroup from './components/add-modify-group'
import Groups from './components/groups'
import Community from './components/community'
import FriendsPage from './components/friends-page'
import Notifications from './components/notifications'
import SetupChatClient from './components/setup-chat-client'
import SendSignupLink from './components/send-signup-link'
import AddProject from './components/add-project'
import Projects from './components/projects'
import Add from './components/add'
import ProjectDetailsPage from './components/project-details'
import KarmaPage from './components/karma-page'
import {checkToken} from './api/backend-api'

import { UserContext } from './contexts/userContext';
import { UserUnreadContext } from './contexts/userUnreadContext';
import { StreamContext } from './contexts/streamContext';
import { defaultUser, UserProfile } from './interfaces/common-types'
import { StreamChat } from 'stream-chat';

import './App.css'

export default function App() {
  const [searchText, setSearchText] = useState('')
  const [userProfile, setUserProfile] = useState(defaultUser.userProfile)
  const [loading, setLoading] = useState(true)
  const [refreshIcon, setRefreshIcon] = useState(false)
  const [unreadCount, setUnreadCount] = useState<number>(0)
  const [unreadChatCount, setUnreadChatCount] = useState<number>(0)
  const [chatClient, setChatClient] = useState<StreamChat>();

  const updateUnreadCount = useCallback((updatedUnreadCount: number) => {
    setUnreadCount(updatedUnreadCount);
  }, [])

  const unreadContextData = useMemo(() => ({
    unreadCount,
    updateUnreadCount
  }), [unreadCount, updateUnreadCount])
  
  const updateUnreadChatCount = useCallback((updatedUnreadChatCount: number) => {
    setUnreadChatCount(updatedUnreadChatCount);
  }, [])

  const updateStreamChat = useCallback((updateStreamChatVal: StreamChat) => {
    setChatClient(updateStreamChatVal);
  }, [])

  if(chatClient && updateUnreadChatCount) {
    chatClient.on((event) => {
        if (event.total_unread_count !== undefined) {
            updateUnreadChatCount(event.total_unread_count)
        }
    });
  }

  const streamChatContextData = useMemo(() => ({
    chatClient,
    updateStreamChat,
    unreadChatCount,
    updateUnreadChatCount,
  }), [chatClient, updateStreamChat, unreadChatCount, updateUnreadChatCount])

  const updateUserProfile = (updatedProfile: UserProfile) => {
    setUserProfile(updatedProfile);
  }

  useEffect(() => {
    checkToken()
    .then((userData) => {
          setUserProfile((userProfile) => (
            {...userProfile, email: userData.email, coins: userData.coins, logged_in: true, id: userData.id, profile_pic: userData.profile_pic, is_staff: userData.is_staff}
        ))
        setLoading(false);
    })
    .catch((error) => {
      console.log("Check Token error result", error)
      setLoading(false);
    })
  }, [userProfile.logged_in])
          

  useEffect(() => {
    setRefreshIcon(true)
  }, [userProfile.profile_pic])

  if(!loading)
  {
    return (
    <>
      <UserContext.Provider value={{userProfile, updateUserProfile}}>
      <UserUnreadContext.Provider value={unreadContextData}>
      <StreamContext.Provider value={streamChatContextData}>
          <div className="App">
              <header>
                <TopBar onChangeSearchText={(searchText: string) => setSearchText(searchText)} refreshIcon={refreshIcon}/>
              </header>
            { userProfile.logged_in ?
            <>
            <SetupChatClient activeUserProfileLite={userProfile} />
            <div className="container bottom-margin padding_section">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-2 left-bar d-none d-sm-block bg-white px-0">
                  <NavList activeUserProfileLite={userProfile}/>
                </div>
                <div className="col-lg-6 col-md-8 col-sm-10"> 
                  <Routes>
                    <Route path="/" element={<Home searchText={searchText}/>} />
                    <Route path="/login" element={<Home searchText={searchText}/>} />
                    <Route path="/projects/:active_tab?" element={<Projects searchText={searchText}/>} />
                    <Route path="/add_project" element={<AddProject newProject={true} />} />
                    <Route path="/project_details" element={<ProjectDetailsPage searchText={searchText}/>} />
                    <Route path="/messages" element={<Messages chatClient={chatClient as StreamChat}/>} />
                    <Route path="/items/:active_tab?" element={<Items searchText={searchText}/>} />
                    <Route path="/add_item" element={<AddItem newItem={true} />} />
                    <Route path="/change_item" element={<AddItem newItem={false} />} />
                    <Route path="/profile" element={<Profile searchText={searchText} userId={userProfile.id}/>} />
                    <Route path="/item_details" element={<ItemDetailsPage itemId={6}/>} />
                    <Route path="/signup" element={<Signup signUp={false}/>} />
                    <Route path="/groups" element={<Groups searchText={searchText} userProfile={userProfile}/>} />
                    <Route path="/community" element={<Community searchText={searchText}/>} />
                    <Route path="/friends" element={<FriendsPage searchText={searchText}/>} />
                    <Route path="/add_modify_group" element={<AddModifyGroup />} />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route path="/send_signup_link" element={<SendSignupLink />} />
                    <Route path="/add" element={<Add />} />
                    <Route path="/karma" element={<KarmaPage />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </div>
              </div>
            </div>
            <div className="footer">
                <div className="d-flex d-sm-none">
                  <NavList activeUserProfileLite={userProfile}/>
                </div>
            </div>
            
            </>
            :
            <div className="main">
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/signup/:token" element={<Signup signUp={true}/>} />
                  <Route path="/password_reset" element={<PasswordReset />} />
                  <Route path="/password_reset_confirm/:uid/:token" element={<PasswordResetConfirm />} />
                  <Route path="*" element={<LandingPage />} />
                </Routes>
            </div>
            }
          </div>
      </StreamContext.Provider>
      </UserUnreadContext.Provider>
      </UserContext.Provider>
    </>
    );
  }
  else
  {
    return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="spinner-border text-success" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
    );
  }

}
