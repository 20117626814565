import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from "react-router-dom"
import { ItemDetails } from '../interfaces/common-types'
import { getItemDetails } from '../api/backend-api'
import { formattedDateToString } from '../utils/formatting'
import BorrowLendFooter from './borrow-lend-footer'
import UserProfileLink from './user-profile-link'
import Carousel from 'react-bootstrap/Carousel'

import './item-details.css'

export default function ItemDetailsPage({
    itemId
  }: {
    itemId: number
  }){
  const location = useLocation();
  const { state } = location;
  const [item, setItem]  = useState<ItemDetails>();
  const refreshInterval = 10000;

  const UpdateItemDetails = useCallback(() => {
    getItemDetails(state.id)
    .then((response) => {
      // console.log("Item Details response: ", response.status)
      // console.log("Item Details Response", response)
      const currentItem: ItemDetails = response.data
      setItem(currentItem)
    })
    .catch((error) => console.log("Items feed Items error: ", error.response.status))
  }, [state.id])

  useEffect(() => {
    getItemDetails(state.id)
    .then((response) => {
      // console.log("Item Details response: ", response.status)
      // console.log("Item Details Response", response)
      const currentItem: ItemDetails = response.data
      setItem(currentItem)
    })
    .catch((error) => console.log("Items feed Items error: ", error.response.status))
    const updateInterval = setInterval(UpdateItemDetails, refreshInterval)
    
    return () => clearInterval(updateInterval)
  }, [state.id, UpdateItemDetails])


  if(!item)
  {
    return(
      <div>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      )
  }

  return(
    <>
    <div className="item-detail-card bg-light shadow-sm mb-3" key={item.name}>            
    <div className="card-body p-0">
      <div className="row item-detail-image">
        <Carousel variant={'dark'} slide={false} interval={null}>
          {item.images.map((image, i) => (
            <Carousel.Item key={i}>
              <img className="item-detail-card-image" src={image.image} alt={item.name}/>
            </Carousel.Item>
          ))
          }
        </Carousel>
      </div>
      <div className="row">
        <h3 className="font-weight-bold text-left mt-3 ml-3">{item.name}</h3>
        <div className="text-left px-3">
          <div className="pl-3">
          <hr />
          <table className="table table-sm table-borderless font-weight-bold table-light">
            <tbody>
              <tr>
                <th className="text-nowrap" scope="row">Cost:</th>
                <td className="text-right pr-3">{item.cost} per {item.cost_unit}</td>
              </tr>
              <tr>
                <th className="text-nowrap" scope="row">Owner:</th>
                <td className="text-right pr-3"><UserProfileLink userProfileInfo={item.owner}></UserProfileLink></td>
              </tr>
              <tr>
                <th className="text-nowrap" scope="row">Status:</th>
                <td className="text-right pr-3">{item.status}</td>
              </tr>
              <tr>
                <th className="text-nowrap" scope="row">Borrower:</th> 
                <td className="text-right pr-3">{ item.borrower ? <UserProfileLink userProfileInfo={item.borrower}></UserProfileLink> : ""} </td>
              </tr>
              <tr>
                <th className="text-nowrap" scope="row">Due:</th>
                <td className="text-right pr-3">{item.due_date ? formattedDateToString(item.due_date) : ""}</td>
              </tr>
              <tr>
                <th className="text-nowrap" scope="row">Description:</th>
                <td className="text-right pr-3">{item.description}</td>
              </tr>
            </tbody>
          </table>
          </div>
          <BorrowLendFooter item={item}></BorrowLendFooter>
        </div>
    </div>           
    </div>
    </div>
    </>
  )
  }