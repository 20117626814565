import React from 'react'
import { useParams } from 'react-router-dom'
import {Tabs, Tab } from 'react-bootstrap'
import ItemsFeed from './items-feed'
import SharedItems from './items-shared'
import UserItems from './items-user-items'

export default function Items({
  searchText
}: {
  searchText: string
}) {

  const {active_tab} = useParams();

  return (
    <div>
      <h3 className="text-left ml-1">Items</h3>
      <Tabs 
        className="font-weight-bold"
        defaultActiveKey={active_tab}
        fill
      >
          <Tab eventKey="feed" title="Feed"><ItemsFeed searchText={searchText}/></Tab>
          <Tab eventKey="shared" title="Shared"><SharedItems searchText={searchText}/></Tab>
          <Tab eventKey="user_items" title="My Items"><UserItems searchText={searchText}/></Tab>
      </Tabs>
    </div>
  )
}