import React, { useContext, useState } from 'react'
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  LoadingIndicator,
  MessageInput,
  MessageList,
  Thread,
  Window,
} from 'stream-chat-react';
import { ChannelSort } from 'stream-chat'

import AddUser from './add-user'
import { UserContext } from '../contexts/userContext';
import { StreamChat } from 'stream-chat'

import './messages.css'

const AddChatMember = async (client: any, userId: string, otherUserId: string) => {
  
  const chatChannel = client.channel('messaging', {
    members: [userId, otherUserId],
    reminders: true,
    read_events: true
  });
  const { app } = await client.getAppSettings();
  console.log("permission version", app.permission_version)
  await chatChannel.watch();
}

export default function Messages({
  chatClient
}: {
  chatClient: StreamChat
}) {
  const userProfile = useContext(UserContext);
  const [showChannelList, setShowChannelList] = useState<boolean>(true)

  const createChatWithMember = (selectedUser: number) => {
    AddChatMember(chatClient, userProfile.userProfile.id.toString(), selectedUser.toString())
  }
  
  const hideChannelList = () => {
    setShowChannelList(!showChannelList)
  }

  const filters = { type: 'messaging', members: { $in: [userProfile.userProfile.id.toString()] } };
  const sort: ChannelSort = { last_message_at: -1 };

  if (!chatClient) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <h3 className="text-left">Chat</h3>
      <p className="text-left"><span className="font-weight-bold">The Chat page is for communicating with others on Partak.</span></p>
      <Chat client={chatClient} theme='messaging light'>
      { showChannelList ? 
      <>
        <div className="d-flex justify-content-start">
          <AddUser buttonName={"Start a New Chat"} addUserFormSubmit={createChatWithMember} friendsOnly={true}/>
        </div>
        <div onClick={hideChannelList}>
          <ChannelList filters={filters} sort={sort} setActiveChannelOnMount={false}/>
        </div>
      </>
      :
      <>
      <div className="d-flex">
        <button className="btn btn-light" onClick={hideChannelList}><i className="fas fa-arrow-left"></i></button>
      </div>
      <Channel>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput grow={true}/>
        </Window>
        <Thread />
      </Channel>
      </>
    }
    </Chat>
    </div>
  )
}