import axios, { AxiosHeaders } from 'axios';
import { config } from '../interfaces/constants';

const ACCESS_TOKEN = 'access_token'
const REFRESH_TOKEN = 'refresh_token'
const STREAM_TOKEN = 'stream_token'

type UserLogin = {email: string, password: string }

const headerWithAuth = {
  'Authorization': sessionStorage.getItem(ACCESS_TOKEN) ? 'Bearer ' + sessionStorage.getItem(ACCESS_TOKEN) : null,
  'Content-Type': 'application/json',
  'accept': 'application/json'
}

const defaultHeader = {
  'Content-Type': 'application/json',
  'accept': 'application/json'
}

const axiosInstance = axios.create({
  baseURL: config.BACKEND_URL,
  timeout: 5000,
  headers: defaultHeader
})

const axiosInstanceWithAUth = axios.create({
  baseURL: config.BACKEND_URL,
  timeout: 5000,
  headers: headerWithAuth
})

const refresh = {
  'refresh': sessionStorage.getItem(REFRESH_TOKEN)
}

export const streamToken = () => {
  return sessionStorage.getItem(STREAM_TOKEN)
}

axiosInstanceWithAUth.interceptors.request.use(
  async (config) => {
    const accessToken = sessionStorage.getItem(ACCESS_TOKEN)
    if (accessToken) {
      (config.headers as AxiosHeaders).set("Authorization", 'Bearer ' + accessToken);
    };

    return config;
  },
  (error) => {
    Promise.reject(error)
  }
);

axiosInstanceWithAUth.interceptors.response.use(
  (result) => {
    return result;
  },
  async (error) => {
    const originalConfig = error.config;

    if (originalConfig.url !== " " && error.response) {
      // Access Token was expired
      if (error.response.status === axios.HttpStatusCode.Unauthorized && !originalConfig._retry) {
        originalConfig._retry = true;
        
        try {
          // console.log("Got unauthorized response")
          const response = await axiosInstanceWithAUth.post('/auth/jwt/refresh/', {
            refresh: sessionStorage.getItem(REFRESH_TOKEN),
          });

          sessionStorage.setItem(ACCESS_TOKEN, response.data.access);
          // console.log("resending with new access token", response.data.access)
          return axiosInstanceWithAUth(originalConfig);
        } catch (_error) {
          // console.log("refresh access token failed")
          axiosInstanceWithAUth.defaults.headers['Authorization'] = null;
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(error)
  }
);

export const refreshToken = () => {
  const tokenPath: string = '/auth/jwt/refresh/'
  console.log("send refresh request");
  refresh['refresh'] = sessionStorage.getItem(REFRESH_TOKEN);
  return axiosInstance.post(tokenPath, refresh)
    .then((response) => {
      console.log("refresh Token results", response);
      sessionStorage.setItem(ACCESS_TOKEN , response.data.access);
      axiosInstanceWithAUth.defaults.headers['Authorization'] = "Bearer " + response.data.access;
      return Promise.resolve(response);
    })
    .catch((error) => {
      console.log("refresh Token error", error.response);
      axiosInstanceWithAUth.defaults.headers['Authorization'] = null;
      return Promise.reject(error);
    })
}

export const checkToken = () => {
  console.log("check token");
  const path: string = '/api/user_details/';
  if(axiosInstanceWithAUth.defaults.headers['Authorization'] !== null){
  return axiosInstanceWithAUth.get(path)
    .then((response: any) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      console.log("No valid token, try refresh")
      refreshToken()
        .then(() => {
          console.log("refresh succeeded, try get user info again")
          return axiosInstanceWithAUth.get(path)
            .then((userData) => {
              return Promise.resolve(userData.data)
            })
            .catch((error) => {
              return Promise.reject(error);
            })
        })
        .catch((error) => {
          console.log("Refresh or get user data failed")
          return Promise.reject(error);
        })
      return Promise.reject(error);
    })
  }
  else {
    return Promise.reject();
  }
}

export const loginRequest = (email: string, password: string) => {
  const login: UserLogin = {email: email, password: password}
  const tokenPath: string = '/auth/jwt/create/'
  return axiosInstance.post(tokenPath, login)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      sessionStorage.setItem(ACCESS_TOKEN , response.data.access);
      sessionStorage.setItem(REFRESH_TOKEN , response.data.refresh);
      sessionStorage.setItem(STREAM_TOKEN, response.data.stream);
      axiosInstanceWithAUth.defaults.headers['Authorization'] = "Bearer " + response.data.access;
      console.log(response.data.access);
      console.log(response.data.refresh);
      return Promise.resolve(response.data);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const logout = () => {
  sessionStorage.setItem(ACCESS_TOKEN , "");
  sessionStorage.setItem(REFRESH_TOKEN , "");
  sessionStorage.setItem(STREAM_TOKEN , "");
  axiosInstanceWithAUth.defaults.headers['Authorization'] = null;
}

export const resetPassword = (email: string) => {
  let userFormData = new FormData();
  userFormData.append('email', email);
  const path: string = '/auth/users/reset_password/'
  return axiosInstance.post(path, userFormData)
    .then((response: any) => {
      console.log("response success: ", response.status, "response data:", response)
      return Promise.resolve(response.data);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const resetPasswordConfirm = (uid: string, token: string, password: string) => {
  let userFormData = new FormData();
  userFormData.append('uid', uid)
  userFormData.append('token', token)
  userFormData.append('new_password', password);
  const path: string = '/auth/users/reset_password_confirm/'// + uid + '/' + token + '/'
  return axiosInstance.post(path, userFormData)
    .then((response: any) => {
      console.log("response success: ", response.status, "response data:", response)
      return Promise.resolve(response.data);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const sendInvite = (email: string, group_id: number) => {
  let userFormData = new FormData();
  userFormData.append('email', email);
  userFormData.append('group_id', group_id.toString())

  const path: string = '/user/api/send_invite_link/'

  return axiosInstanceWithAUth.post(path, userFormData)
    .then((response: any) => {
      console.log("response success: ", response.status, "response data:", response)
      return Promise.resolve(response.data);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const getMyItems = () => {
  console.log("get my Items request")
    const path: string = '/item/api/lend_shelf/'
    
    return axiosInstanceWithAUth.get(path)
}

export const getUserItemsRequest = (userId: number) => {
  console.log("user items request. User: ", userId);
  const path: string = 'item/api/lend_shelf/' + userId.toString() + '/'

  return axiosInstanceWithAUth.get(path)
}

export const getSharedItems = () => {
  console.log("get Shared Items request")
  const path: string = '/item/api/borrowed_shelf/'
   
  return axiosInstanceWithAUth.get(path)
}

export const getItemsFeed = () => {
  console.log("get Items Feed request")
  const path: string = '/item/api/items_feed/'

  return axiosInstanceWithAUth.get(path)
}

export const communityItemsRequest = (communityId: number) => {
  console.log("get community items")
  const path: string = '/item/api/community_items/' + communityId.toString() + '/'

  return axiosInstanceWithAUth.get(path)
}

export const getItemDetails = (itemId: number) => {
  console.log("get Items Feed request")
  const path: string = '/item/api/item_details/' + itemId + '/'
   
  return axiosInstanceWithAUth.get(path)
}

export const sendAddItem = (itemName: string, itemDescription: string, itemCost: number, itemCostUnit: string, itemStatus: string, images: File[]) => {
  let itemFormData = new FormData();
  itemFormData.append('name', itemName);
  itemFormData.append('description', itemDescription);
  itemFormData.append('cost', itemCost.toString());
  itemFormData.append('cost_unit', itemCostUnit);
  itemFormData.append('status', itemStatus);
  Array.from(images).forEach((image, i) => {
    itemFormData.append(`uploaded_images`, image);
  });
  

  const tokenPath: string = '/item/api/add_item/'
  axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
  return axiosInstanceWithAUth.post(tokenPath, itemFormData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const sendUpdateItem = (itemId: number, itemName: string, itemDescription: string, itemCost: number, itemCostUnit: string, itemStatus: string, images?: File[]) => {
  let itemFormData = new FormData();
  itemFormData.append('name', itemName);
  itemFormData.append('description', itemDescription);
  itemFormData.append('cost', itemCost.toString());
  itemFormData.append('cost_unit', itemCostUnit);
  itemFormData.append('status', itemStatus);
  if(images) {
    Array.from(images).forEach((image, i) => {
      itemFormData.append(`uploaded_images`, image);
    });
  }
  

  const tokenPath: string = '/item/api/update_item/' + itemId + '/'
  axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
  return axiosInstanceWithAUth.put(tokenPath, itemFormData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const getItemImages = (itemId: number) => {

  const path: string = '/item/api/item_images/' + itemId + '/'
  return axiosInstanceWithAUth.get(path)
  .then((response: any) => {
    // console.log("response success: ", response.status)
    console.log("getItemImages response:", response);
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    console.log("getItemImages response error: ", error);
    return Promise.reject(error);
  })
}

export const sendDeleteItemImage = (imageId: number) => {

  const path: string = '/item/api/delete_item_image/' + imageId + '/'
  return axiosInstanceWithAUth.delete(path)
  .then((response: any) => {
    // console.log("response success: ", response.status)
    console.log("sendDeleteItemImage response:", response);
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    console.log("sendDeleteItemImage response error: ", error);
    return Promise.reject(error);
  })
}

export const sendDeleteItem = (itemId: number) => {
  const tokenPath: string = '/item/api/delete_item/' + itemId + '/'
  return axiosInstanceWithAUth.delete(tokenPath)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const changeItemStatus = (itemId: number, status: string) => {
  const tokenPath: string = '/item/api/update_item/' + itemId + '/'
  let itemFormData = new FormData();
  itemFormData.append('status', status);

  axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
  return axiosInstanceWithAUth.put(tokenPath, itemFormData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const endBorrowApi = (itemId: number, endBorrowType: string) => {
  const tokenPath: string = '/item/api/end_borrow/' + itemId + '/'
 
  return axiosInstanceWithAUth.put(tokenPath, endBorrowType)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}
export const requestToBorrowApi = (itemId: number, borrowerId: number, borrowDate: string, returnDate: string, exchangeMethod: string, requestComments: string, status: string) => {
  const tokenPath: string = '/item/api/request_borrow/' + itemId + '/'
  let itemFormData = new FormData();
  itemFormData.append('status', status);
  itemFormData.append('borrower_id', borrowerId.toString());
  itemFormData.append('date_borrowed', borrowDate);
  itemFormData.append('due_date', returnDate);
  itemFormData.append('exchange_method', exchangeMethod);
  itemFormData.append('request_comments', requestComments);
  console.log("itemFormData", borrowerId)

  axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
  return axiosInstanceWithAUth.put(tokenPath, itemFormData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const inSearchOfRequest = (isoRequestText: string) => {
  const path: string = 'item/api/in_search_of/';

  return axiosInstanceWithAUth.post(path, isoRequestText)
  .then((response: any) => {
    // console.log("response success: ", response.status)
    console.log("inSearchOfRequest response",response);
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    console.log("response error: ", error);
    return Promise.reject(error);
  })
}

export const getUserProfile = (userId: number) => {
  const path: string = '/api/user_details/' + userId.toString() + "/";
  // console.log("getuserprofile:", path)
  return axiosInstanceWithAUth.get(path)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const sendAddUser = (signUp: boolean, firstName: string, lastName: string, emailAddress: string, streetAddress: string, city: string, state: string, zipCode: string, profilePic?: File, password?: string, token?: string) => {
  // Create new user, check for errors in form or email
  let userFormData = new FormData();
  userFormData.append('first_name', firstName);
  userFormData.append('last_name', lastName);
  userFormData.append('email', emailAddress);
  userFormData.append('street_address', streetAddress);
  userFormData.append('city', city);
  userFormData.append('state', state);
  userFormData.append('zip_code', zipCode);
  if(profilePic) {
    console.log("Profile Pic: ", profilePic, profilePic.name)
    userFormData.append('profile_pic', profilePic, profilePic.name);
  }

  if(signUp) {
    if(password) {
      userFormData.append('password', password);
    }

    let path: string = '/user/api/sign_up/'

    if(token) {
      userFormData.append('token', token)
      path = '/user/api/sign_up_token/'
    }
    
    axiosInstance.defaults.headers['content-type'] = 'multipart/form-data'
    return axiosInstance.post(path, userFormData)
      .then((response: any) => {
        // console.log("response success: ", response.status)
        console.log(response);
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        console.log("response error: ", error);
        return Promise.reject(error);
      })
  }
  else {

    const path: string = '/user/api/update_profile/'
    axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
    return axiosInstanceWithAUth.put(path, userFormData)
      .then((response: any) => {
        // console.log("response success: ", response.status)
        console.log(response);
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        console.log("response error: ", error);
        return Promise.reject(error);
      })
  }
}

export const getMyGroups = () => {
  const path: string = 'user/api/user_groups/';

  return axiosInstanceWithAUth.get(path)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const sendCreateGroup = (groupName: string, userId: string, description: string) => {
  const path: string = 'user/api/create_group/';
  const requestData = { "group_name": groupName, "group_owner": userId, "description": description}

  return axiosInstanceWithAUth.post(path, requestData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const retrieveGroupDetails = (communityId: number) => {
  const path: string = 'user/api/group_info/' + communityId.toString() + '/';

  return axiosInstanceWithAUth.get(path)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const updateGroupDetails = (communityId: number, communityName: string, communityDescription: string) => {
  const path: string = 'user/api/group_info/' + communityId.toString() + '/';
  let GroupFormData = new FormData();
  GroupFormData.append('group_name', communityName);
  GroupFormData.append('description', communityDescription);

  axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
  return axiosInstanceWithAUth.patch(path, GroupFormData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      // console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const communityMemberListRequest = (communityId: number) => {
  const path: string = 'user/api/community_members/' + communityId.toString() + '/';

  return axiosInstanceWithAUth.get(path)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      // console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const addCommunityMemberRequest = (communityId: number, userId: number) => {
  const path: string = 'user/api/add_member/' + communityId.toString() + '/' + userId.toString() + '/';

  return axiosInstanceWithAUth.patch(path)
    .then((response: any) => {
      // console.log("response success: ", response.status);
      // console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const removeCommunityMemberRequest = (communityId: number, memberId: number) => {
  const path: string = 'user/api/remove_member/' + communityId.toString() + "/" + memberId.toString() + '/';

  return axiosInstanceWithAUth.patch(path)
    .then((response: any) => {
      // console.log("response success: ", response.status);
      // console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const searchForUser = async (searchText: string) => {
  const path: string = 'user/api/search_users/' + searchText + '/';

  try {
    const response = await axiosInstanceWithAUth.get(path);
    return await Promise.resolve(response);
  } catch (error) {
    console.log("response error: ", error);
    return await Promise.reject(error);
  }
}

export const searchForFriend = async (searchText: string) => {
  const path: string = 'user/api/search_friends/' + searchText + '/';

  try {
    const response = await axiosInstanceWithAUth.get(path);
    return await Promise.resolve(response);
  } catch (error) {
    console.log("response error: ", error);
    return await Promise.reject(error);
  }
}

export const getCommonGroups = async (userId: number) => {
  const path: string = 'user/api/common_groups/' + userId + '/';

  try {
    const response = await axiosInstanceWithAUth.get(path);
    return await Promise.resolve(response);
  } catch (error) {
    console.log("response error: ", error);
    return await Promise.reject(error);
  }
}

export const getNotificationsRequest = () => {
  const path: string = 'notification/api/notifications/';

  return axiosInstanceWithAUth.get(path)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      // console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const getUnreadCount = () => {
  const path: string = 'notification/api/unread_count/';

  return axiosInstanceWithAUth.get(path)
  .then((response: any) => {
    // console.log("response success: ", response.status)
    // console.log(response);
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    console.log("response error: ", error);
    return Promise.reject(error);
  })
}

export const notificationWasRead = (id: number) => {
  const path: string = 'notification/api/notification_read/' + id.toString() + '/';
  const requestData = { "unread_status": false }

  return axiosInstanceWithAUth.patch(path, requestData)
  .then((response: any) => {
    // console.log("response success: ", response.status)
    // console.log(response);
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    console.log("response error: ", error);
    return Promise.reject(error);
  })
}

export const getUserProjects = (userId: number) => {
  console.log("get my Projects request")
    const path: string = '/project/api/user_projects/' + userId + '/'
    
    return axiosInstanceWithAUth.get(path)
}

export const sendAddProject = (projectName: string, projectDescription: string) => {
  let itemFormData = new FormData();
  itemFormData.append('name', projectName);
  itemFormData.append('description', projectDescription);
  

  const tokenPath: string = '/project/api/add_project/'
  axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
  return axiosInstanceWithAUth.post(tokenPath, itemFormData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const sendUpdateProject = (projectId: number, projectName: string, projectDescription: string) => {
  let itemFormData = new FormData();
  itemFormData.append('name', projectName);
  itemFormData.append('description', projectDescription);

  const tokenPath: string = '/project/api/update_project/' + projectId + '/'
  axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
  return axiosInstanceWithAUth.put(tokenPath, itemFormData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const getProjectDetails = (projectId: number) => {
  console.log("get Project Feed request")
  const path: string = '/project/api/project_details/' + projectId + '/'
   
  return axiosInstanceWithAUth.get(path)
}

export const sendAddProjectPost = (projectId: string, text: string, images?: File[]) => {
  let postFormData = new FormData();
  postFormData.append('project', projectId);
  postFormData.append('text', text);
  if (images) {
    Array.from(images).forEach((image, i) => {
      postFormData.append(`images`, image);
    })
  }
  

  const tokenPath: string = '/post/api/add_project_post/'
  axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
  return axiosInstanceWithAUth.post(tokenPath, postFormData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const sendAddPost = (text: string, images?: File[]) => {
  let postFormData = new FormData();
  postFormData.append('text', text);
  if (images) {
    Array.from(images).forEach((image, i) => {
      postFormData.append(`images`, image);
    });
  }

  const tokenPath: string = '/post/api/add_post/'
  axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
  return axiosInstanceWithAUth.post(tokenPath, postFormData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const sendUpdatePost = (post: number, text: string, images?: File[]) => {
  let itemFormData = new FormData();
  itemFormData.append('text', text);
  if(images) {
    Array.from(images).forEach((image, i) => {
      itemFormData.append(`images`, image);
    });
  }
  

  const tokenPath: string = '/post/api/update_post/' + post + '/'
  axiosInstanceWithAUth.defaults.headers['content-type'] = 'multipart/form-data'
  return axiosInstanceWithAUth.put(tokenPath, itemFormData)
    .then((response: any) => {
      // console.log("response success: ", response.status)
      console.log(response);
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      console.log("response error: ", error);
      return Promise.reject(error);
    })
}

export const sendDeletePost = (postId: number) => {

  const path: string = '/post/api/delete_post/' + postId + '/'
  return axiosInstanceWithAUth.delete(path)
  .then((response: any) => {
    // console.log("response success: ", response.status)
    console.log("sendDeletePost response:", response);
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    console.log("sendDeletePost response error: ", error);
    return Promise.reject(error);
  })
}

export const getPostImages = (postId: number) => {

  const path: string = '/post/api/post_images/' + postId + '/'
  return axiosInstanceWithAUth.get(path)
  .then((response: any) => {
    // console.log("response success: ", response.status)
    console.log("getPostImages response:", response);
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    console.log("getPostImages response error: ", error);
    return Promise.reject(error);
  })
}

export const sendDeletePostImage = (imageId: number) => {

  const path: string = '/post/api/delete_post_image/' + imageId + '/'
  return axiosInstanceWithAUth.delete(path)
  .then((response: any) => {
    // console.log("response success: ", response.status)
    console.log("sendDeleteItemImage response:", response);
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    console.log("sendDeleteItemImage response error: ", error);
    return Promise.reject(error);
  })
}

export const getProjectPostsFeed = () => {
  console.log("get Project Posts Feed request")
  const path: string = '/post/api/project_posts_feed/'

  return axiosInstanceWithAUth.get(path)
}

export const getUserPosts = (userId: number) => {
  console.log("get user posts request")
  const path: string = '/post/api/user_posts/' + userId + "/"

  return axiosInstanceWithAUth.get(path)
}

export const getHomeFeed = () => {
  console.log("get All feed request")
  const path: string = '/post/api/home_feed/'

  return axiosInstanceWithAUth.get(path)
}

export const addComment = (post_id: number, comment_text: string) => {
  let commentFormData = new FormData();
  commentFormData.append('post', post_id.toString());
  commentFormData.append('text', comment_text);

  const path: string = '/post/api/post_add_comment/'

  return axiosInstanceWithAUth.post(path, commentFormData)
  
  .then((response: any) => {
    // console.log("response success: ", response.status)
    console.log(response);
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    console.log("response error: ", error);
    return Promise.reject(error);
  })
}

export const sendKarmaApi = (user_id: number, karma_amount: number) => {
  let karmaFormData = new FormData();
  karmaFormData.append('karma_amount', karma_amount.toString());

  const path: string = '/user/api/send_karma/' + user_id + "/";

  return axiosInstanceWithAUth.put(path, karmaFormData)
  
  .then((response: any) => {
    // console.log("response success: ", response.status)
    console.log(response);
    return Promise.resolve(response);
  })
  .catch((error: any) => {
    console.log("response error: ", error);
    return Promise.reject(error);
  })
}

export const friendRequest = async (userId: number) => {
  const path: string = 'user/api/add_friend_request/' + userId + '/';

  try {
    const response = await axiosInstanceWithAUth.post(path);
    return await Promise.resolve(response);
  } catch (error) {
    console.log("response error: ", error);
    return await Promise.reject(error);
  }
}

export const updateFriendRequest = async (userId: number, decision: string) => {
  let formData = new FormData();
  formData.append('request_decision', decision);
  
  const path: string = 'user/api/update_friend_request/' + userId + '/';

  try {
    const response = await axiosInstanceWithAUth.put(path, formData);
    return await Promise.resolve(response);
  } catch (error) {
    console.log("response error: ", error);
    return await Promise.reject(error);
  }
}

export const unfriendRequest = async (userId: number) => {
  const path: string = 'user/api/unfriend/' + userId + '/';

  try {
    const response = await axiosInstanceWithAUth.put(path);
    return await Promise.resolve(response);
  } catch (error) {
    console.log("response error: ", error);
    return await Promise.reject(error);
  }
}

export const friendsListRequest = async () => {
  const path: string = 'user/api/friend_list/'

  try {
    const response = await axiosInstanceWithAUth.get(path);
    return await Promise.resolve(response);
  } catch (error) {
    console.log("response error: ", error);
    return await Promise.reject(error);
  }
}

export const friendsItemsRequest = async () => {
  console.log("get friends items")
  const path: string = '/item/api/friends_items/'

  try {
    const response = await axiosInstanceWithAUth.get(path);
    return await Promise.resolve(response);
  } catch (error) {
    console.log("response error: ", error);
    return await Promise.reject(error);
  }
}