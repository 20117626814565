import React, { useContext } from 'react'
import { NavLink } from "react-router-dom"

import { UserUnreadContext } from '../contexts/userUnreadContext';
import { StreamContext } from '../contexts/streamContext';
import {UserProfileLite} from '../interfaces/common-types'
import './nav-sidebar.css'
import { nav_sidebar_links } from './nav-sidebar-data'

export default function NavList(
  {
    activeUserProfileLite
  }: {
    activeUserProfileLite: UserProfileLite
  }) {
    const {unreadCount} = useContext(UserUnreadContext)
    const {unreadChatCount} = useContext(StreamContext)

    nav_sidebar_links[5].icon = <i className="far fa-comments iconClass">{(unreadChatCount > 0) ? <span className="badge rounded-pill badge-notification badge-danger">{unreadChatCount}<span className="visually-hidden">unread chats</span></span> : <span></span>}</i>
    nav_sidebar_links[7].icon = <i className="far fa-bell iconClass">{(unreadCount > 0) ? <span className="badge rounded-pill badge-notification badge-danger">{unreadCount}<span className="visually-hidden">unread notifications</span></span> : <span></span>}</i>
    
    return (
      <div className="container">
        <div className="row">
        <ul className="nav" id="footer-bar">

          { nav_sidebar_links.map((navItem) => {
            if (navItem.title === 'Karma: ') {
              return(
              <div className="d-none d-sm-flex" key={navItem.title}>
                <li>
                  <NavLink 
                    to={navItem.path}
                    className={({ isActive }) =>
                    isActive ? navItem.activeClassName: navItem.inactiveClassName
                  }
                  >
                    <div className="icon">{navItem.icon}</div>
                    <div className="name">
                      {navItem.title}
                      {activeUserProfileLite.coins}
                    </div>
                    </NavLink>
                </li>
              </div>
              )
            }
            else {
              return(
                
                <div className="col p-0" key={navItem.title}>
                  <li>
                    <NavLink 
                      to={navItem.path}
                      className={({ isActive }) =>
                      isActive ? navItem.activeClassName: navItem.inactiveClassName
                    }
                    >
                      <div className="icon">{navItem.icon}</div>
                      <div className="name">{navItem.title}</div>
                    </NavLink>
                  </li>
                </div>
                
                )
            }            
          })}
          
        </ul>
        </div>
        </div>
    )
  }