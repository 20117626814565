import React from 'react'


export default function PageNotFound() {
  return (
    <div>
      <h1>Page Not Found</h1>
      <p>Error 404</p>
    </div>
  )
}