import React, { useState, useEffect } from 'react'
import { sendAddItem, sendUpdateItem, sendDeleteItemImage, getItemImages } from '../api/backend-api';
import { useNavigate, useLocation } from 'react-router-dom';
import { ItemDetails, ItemImage, defaultItemDetails } from '../interfaces/common-types';
import axios from 'axios';

import './error.css'
import './item-details.css'


const MAX_DESCRIPT_LENGTH = 500;
const MAX_ITEM_NAME_LENGTH = 100;


export default function AddItem({
  newItem
}:{
  newItem: boolean
}) {
  const location = useLocation();
  const { state } = location;

  const itemDetails = newItem ? defaultItemDetails : state.item as ItemDetails

  const [itemName, setItemName] = useState(newItem ? "" : itemDetails.name); 
  const [itemDescription, setItemDescription] = useState(newItem ? "" : itemDetails.description);
  const [itemCost, setItemCost] = useState<number>(newItem ? 0 : itemDetails.cost);
  const [itemCostUnit] = useState("Day");
  const [itemStatus, setItemStatus] = useState(newItem ? "Available" : itemDetails.status);
  const [itemId] = useState(newItem ? 0 : itemDetails.id)
  const [itemImages, setItemImages] = useState<ItemImage[]>(itemDetails.images)
  const [refreshImages, setRefreshImages] = useState<Boolean>(false)
  const [tempImages, setTempImages] = useState<File[]>();
  const [formError, setFormError] = useState<string>("")
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const navigateToRoot = useNavigate()

  const toggleRefreshImage = () => {
    setRefreshImages(!refreshImages)
  }
  useEffect(() => {
    if(!newItem) {
    getItemImages(itemDetails.id)
    .then((response) => {
      // console.log("Item Images Response", response)
      const updatedImages: ItemImage[] = response.data.images
      setItemImages(updatedImages)
    })
    .catch((error) => console.log("Items feed Items error: ", error.response.status))
  }
  }, [refreshImages])

  const onAddItemFormSubmit = (event: any) => {
    event.preventDefault();
    setDisableSubmit(true)
    if (!isValidForm()){
      // console.log("form invalid")
      setFormError("Select valid item image!")
      setDisableSubmit(false)
      return
    }
    // console.log("form valid")

    if(newItem && tempImages) {
      sendAddItem(itemName, itemDescription, itemCost, itemCostUnit, itemStatus, tempImages)
        .then((data: any)=>{
          // console.log("addItem Request data: ", data)
          if(data.status === axios.HttpStatusCode.Created){
            navigateToRoot('/items/user_items')
          }
          else{
            console.log("Error:", data.status)
          }
        })
        .catch((error: any)=> {
          let errString:string = "";
          Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
          //Now capitable this
          errString = errString.charAt(0).toUpperCase() + errString.slice(1);
          setFormError(errString)
          // console.log("Form Error: ", formError)
          setDisableSubmit(false)
        })
    }
    else if(!newItem) {
      sendUpdateItem(itemId, itemName, itemDescription, itemCost, itemCostUnit, itemStatus, tempImages)
        .then((data: any)=>{
          // console.log("Update Item Request data: ", data)
          if(data.status === axios.HttpStatusCode.Ok){
            navigateToRoot('/items/user_items')
          }
          else{
            console.log("Error:", data.status)
          }
        })
        .catch((error: any)=> {
          // console.log("Login Error: ", error.response.data)
          let errString:string = "";
          Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
          //Now capitable this
          errString = errString.charAt(0).toUpperCase() + errString.slice(1);
          setFormError(errString)
          // console.log("Form Error: ", formError)
          setDisableSubmit(false)
        })
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files
    if(fileList) {
      const fileArray = Array.from(fileList) as File[]
      // console.log("File Array:", fileArray)
      setTempImages(fileArray)
    }
    return
  }

  const deleteImage = (image: ItemImage) => {
    // console.log("Item number: ", image.id, "Item url: ", image.image)
    sendDeleteItemImage(image.id)
      .then((data: any)=>{
        // console.log("Delete item: ", data)
        toggleRefreshImage();
      })
      .catch((error: any)=> {
        // console.log("Delete item Error: ", error)
      })
  }

  const deleteFile = (arrayIndex: number) => {
    console.log("starting array ", tempImages)
      if(tempImages && tempImages.length > 0) {
        if(tempImages.length === 1) {
          setTempImages(undefined)
        }
        else if(arrayIndex === tempImages.length) {
          const newImagesArray = tempImages.slice(0, arrayIndex-1)
          setTempImages(newImagesArray)
          // console.log("ending array ", newImagesArray)
        } 
        else if(arrayIndex === 0) {
          const newImagesArray = tempImages.slice(arrayIndex+1, tempImages.length)
          setTempImages(newImagesArray)
          // console.log("ending array ", newImagesArray)
        }
        else if(arrayIndex < tempImages.length) {
          const newImagesLowerArray = tempImages.slice(0, arrayIndex)
          const newImagesArray = newImagesLowerArray.concat(tempImages.slice(arrayIndex+1, tempImages.length))
          setTempImages(newImagesArray)
          // console.log("ending array ", newImagesArray)
        }
    }
  }

  const isValidForm = () => {
    if (itemImages.length > 0  || (tempImages && tempImages.length > 0)) {
      return true;
    }
    else {
      return false;
    }
  }

  return (
    <div>
      <h3 className="text-left">{newItem ? "Add Item" : "Update Item"}</h3>
      <form onSubmit={onAddItemFormSubmit} method="post">
        <div className="form-group text-left">
          <label htmlFor="itemName" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Name</label>
          <input 
            type="text" 
            onChange={(event)=>{setItemName(event.target.value.slice(0,MAX_ITEM_NAME_LENGTH))}} 
            className="form-control" id="itemName" aria-describedby="nameHelp"
            value={itemName}
            placeholder="Item Name" 
            required
          />
          <small id="nameHelp" className="form-text text-muted ml-1">Choose a name that will be easy for others to find it. Generic names are usally better than using the brand name. Be specific too. For example, use Electric Circular Saw, instead of Saw.</small>
        </div>
        <div className="form-group text-left">
          <label htmlFor="itemDescription" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Description</label>
          <textarea 
            onChange={(event)=>{setItemDescription(event.target.value.slice(0, MAX_DESCRIPT_LENGTH))}} 
            className="form-control" 
            id="itemDescription" 
            rows={3} 
            aria-describedby="descriptionHelp"
            value={itemDescription} 
            placeholder="Item Description" 
            required
          ></textarea>
          <small id="descriptionHelp" className="form-text text-muted ml-1">Add any important details, like specs and condition of the item. {itemDescription.length}/{MAX_DESCRIPT_LENGTH} characters</small>
        </div>
        <div className="form-row text-left">
          <div className="col">
            <label htmlFor="itemCost" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Cost</label>
            <input 
              type="number"
              onChange={(event)=>{setItemCost(event.target.valueAsNumber)}}
              className="form-control"
              id="itemCost"
              value={itemCost}
              placeholder="0" 
              required
            />
          </div>
          <div className="align-self-end mb-2 col">
            <span className="">Per Day</span>
          </div>
        </div>
        <div className="form-row text-left">
          {itemImages.map((image, i) => (
              <div key={"storedImage" + i}>
                <img className="my-2 item-image-thumbnail" src={image.image} alt={itemName} />
                <div className="delete-img">
                  <button className="btn btn-light" onClick={() => deleteImage(image)} type="button"><i className="fas fa-trash-alt"></i></button>
                </div>
              </div>
            ))
          }
          {tempImages && tempImages.map((image, i) => (
              <div key={"tempImage" + i}>
                <img className="my-2 item-image-thumbnail" src={URL.createObjectURL(image)} alt={image.name} />
                <div className="delete-img">
                  <button className="btn btn-light" onClick={() => deleteFile(i)} type="button"><i className="fas fa-trash-alt"></i></button>
                </div>
              </div>
            ))
          }
        </div>
        <div className="form-group text-left">
          <label htmlFor="itemImage" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Upload Image</label>
          <input type="file" 
            multiple
            className="form-control-file" 
            id="itemImage" 
            accept="image/png, image/jpeg, image/jpg" 
            onChange={handleFileChange}
            />
        </div>
        
        <div className="form-group text-left">
          <label htmlFor="itemStatus" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Status</label>
          <select onChange={(event)=>{setItemStatus(event.target.value)}} className="custom-select" id="itemDescription" defaultValue="Available" required>
            <option>Available</option>
            <option>Not Available</option>
          </select>
        </div>
        <button type="submit" className="btn btn-primary btn-lg btn-block" disabled={disableSubmit}>Add Item</button>
        <label className="form-error">{formError}</label>
      </form>
    </div>
  )
}