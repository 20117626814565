import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from "react-router-dom"

import {Group, UserProfile} from '../interfaces/common-types'
import { UserContext } from '../contexts/userContext';
import { getCommonGroups } from '../api/backend-api'

export default function ProfileCommonGroups({
  userId
}: {
  userId: number;
}) {
  const {userProfile, updateUserProfile} = useContext(UserContext)
  const [commonGroups, setCommonGroups] = useState<Group[]>([]);

  const myProfile = () => {
    return (userProfile.id === userId)
  }

  useEffect(() => {
    // Get common groups from t
    getCommonGroups(userId)
      .then((response) => {
        setCommonGroups(response.data);
      });
  }, [userId]);

  return (
    <div className="card bg-white shadow-sm mb-3">            
      <div className="card-body p-0">
        {myProfile() ? <h4 className="text-start m-2">Your Groups</h4> 
          : <h4 className="text-start m-2">Mutual Groups</h4>
        }
        <ul className="list-group list-group-flush">
          {commonGroups.map((group) => (
            <NavLink to={{pathname: '/community'}} state={{ communityDetails: group}} className="text-decoration-none" key={group.id}>
              <li className="list-group-item text-left" key={group.id}>{group.group_name}</li>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};
