import React, { useState, useContext } from 'react'
import axios from 'axios'
import { useNavigate, useLocation } from "react-router-dom"
import { ItemDetails } from '../interfaces/common-types'
import { NavLink } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import { formattedDateToString } from '../utils/formatting'
import { sendDeleteItem, changeItemStatus, requestToBorrowApi, endBorrowApi } from '../api/backend-api'
import { UserContext } from '../contexts/userContext';


export default function BorrowLendFooter({
 item
}:{
  item: ItemDetails
}) {
  const navigateToRoot = useNavigate()
  const location = useLocation();
  const { state } = location;

  const userProfile = useContext(UserContext)
  const currentDate = new Date()
  const timezoneOffset = currentDate.getTimezoneOffset()  // offset in minutes
  currentDate.setMinutes(currentDate.getMinutes() - timezoneOffset)
  const maxDate = new Date()
  const minutesIn365Days = 525600
  maxDate.setMinutes(maxDate.getMinutes() + (minutesIn365Days - timezoneOffset))

  const currentDateString: string = currentDate.toISOString().substring(0, 10)
  const maxDateString: string = maxDate.toISOString().substring(0, 10)

  const [borrowDate, setBorrowDate] = useState<string>(currentDateString)
  const [returnDate, setReturnDate] = useState<string>(borrowDate)
  const [showBorrowRequestModal, setShowBorrowRequestModal] = useState<boolean>(false);
  const toggleShowBorrowRequestModal = () => setShowBorrowRequestModal(!showBorrowRequestModal);
  const [exchangeMethod, setExchangeMethod] = useState<string>("Borrower Pick Up")
  const [requestComments, setRequestComments] = useState<string>("")
  const [costEstimate, setCostEstimate] = useState<number>(item.cost)

  const validateDates = () => {
    const compareBorrowDate = new Date(borrowDate)
    const compareReturnDate = new Date(returnDate)


    if((compareBorrowDate < currentDate) || (compareBorrowDate > maxDate)) {
      compareBorrowDate.setMinutes(currentDate.getMinutes() - timezoneOffset)
      setBorrowDate(currentDate.toISOString().substring(0, 10))
    }

    if((compareBorrowDate > compareReturnDate) || (compareReturnDate > maxDate)) {
      compareReturnDate.setDate(compareBorrowDate.getDate())
      setReturnDate(borrowDate)
    }

    let numberOfDays = compareReturnDate.getDate() - compareBorrowDate.getDate()
    if(numberOfDays === 0) {
      numberOfDays = 1
    }

    setCostEstimate(numberOfDays * item.cost)
  }

  const deleteItemButtonRequest = (event: React.MouseEvent) => {
    event.preventDefault();
    sendDeleteItem(state.id)
      .then((data: any)=>{
        console.log("Delete Item: ", data)
        if(data.status === axios.HttpStatusCode.NoContent){
          navigateToRoot('/items')
        }
      })
      .catch((error: any)=> {
        console.log("Login Error: ", error)
      })
  }

  const RequestToBorrow = (event: React.MouseEvent) => {
    console.log(userProfile.userProfile)
    const utcBorrowDate = new Date(borrowDate)
    utcBorrowDate.setMinutes(utcBorrowDate.getMinutes() + timezoneOffset)
    const utcBorrowDateStr = utcBorrowDate.toISOString().substring(0, 10)
    const utcReturnDate = new Date(returnDate)
    utcReturnDate.setMinutes(utcReturnDate.getMinutes() + timezoneOffset)
    const utcReturnDateStr = utcReturnDate.toISOString().substring(0, 10)

    requestToBorrowApi(item.id, userProfile.userProfile.id, utcBorrowDateStr, utcReturnDateStr, exchangeMethod, requestComments, "Borrow Requested")
      .then((data: any)=>{
        console.log("Request to Borrow Item: ", data)
        if(data.status === axios.HttpStatusCode.Ok){
          window.location.reload();
        }
      })
      .catch((error: any)=> {
        console.log("Login Error: ", error)
      })
  }

  const AcceptRequest = (event: React.MouseEvent) => {
    changeItemStatus(item.id, "Borrow Accepted")
      .then((data: any)=>{
        console.log("Accept Borrow Request: ", data)
        if(data.status === axios.HttpStatusCode.Ok){
          window.location.reload();
        }
      })
      .catch((error: any)=> {
        console.log("Login Error: ", error)
      })
  }

  const CancelDeclineOrReturn = (endBorrowType: string) => {
    endBorrowApi(item.id, endBorrowType)
      .then((data: any)=>{
        console.log("CancelDeclineOrReturn: ", data)
        if(data.status === axios.HttpStatusCode.Ok){
          window.location.reload();
        }
      })
      .catch((error: any)=> {
        console.log("Login Error: ", error)
      })
  }

  const BorrowerReceivedItem = (event: React.MouseEvent) => {
    changeItemStatus(item.id, "Borrowed")
      .then((data: any)=>{
        console.log("Borrower Received Item: ", data)
        if(data.status === axios.HttpStatusCode.Ok){
          window.location.reload();
        }
      })
      .catch((error: any)=> {
        console.log("Login Error: ", error)
      })
  }

  const ReturnItem = (event: React.MouseEvent) => {
    changeItemStatus(item.id, "Return Pending")
      .then((data: any)=>{
        console.log("Return Item: ", data)
        if(data.status === axios.HttpStatusCode.Ok){
          window.location.reload();
        }
      })
      .catch((error: any)=> {
        console.log("Login Error: ", error)
      })
  }

  const BorrowStatus = () => {
    return(
    <table className="table table-sm table-borderless font-weight-bold table-light">
      <tbody>
        <tr>
          <th className="text-left text-nowrap" scope="row">Borrower:</th>
          <td className="text-right  pr-3">{item.borrower.first_name} {item.borrower.last_name}</td>
        </tr>
        <tr>
          <th className="text-left text-nowrap" scope="row">Borrow Date:</th>
          <td className="text-right pr-3">{formattedDateToString(item.date_borrowed)}</td>
        </tr>
        <tr>
          <th className="text-left text-nowrap" scope="row">Return Date:</th>
          <td className="text-right pr-3">{formattedDateToString(item.due_date)}</td>
        </tr>
        <tr>
          <th className="text-left text-nowrap" scope="row">Method of Exchange:</th>
          <td className="text-right pr-3">{item.exchange_method}</td>
        </tr>
        <tr>
          <th className="text-left text-nowrap" scope="row">Comments:</th> 
          <td className="text-right pr-3">{item.request_comments}</td>
        </tr>
      </tbody>
    </table>
    )
  }

  const BorrowWorkflowHeading = () => {
    if(userProfile.userProfile.id === item.owner.id)
    {
      return (
        <div className="pl-3">
        {(item.status === "Borrow Requested") ?
          <div>
            {BorrowStatus()}
            <h3>Borrow Requested by {item.borrower.first_name} {item.borrower.last_name}</h3>
            <hr />
            <button onClick={AcceptRequest} className="btn btn-success float-right mb-3 mr-3">Accept</button>
            <button onClick={() => CancelDeclineOrReturn("Decline")} className="btn btn-danger float-right mb-3 mr-3">Decline</button>
          </div>
        : <></>}
        {(item.status === "Borrow Accepted") ?
          <div>            
            {BorrowStatus()}
            <h3>Borrow Accepted: Coordinate Item Pick-up/Delivery</h3>
            <hr />
            <button onClick={BorrowerReceivedItem} className="btn btn-success float-right mb-3 mr-3">Item Received</button>
            <button onClick={() => CancelDeclineOrReturn("Cancel")} className="btn btn-danger float-right mb-3 mr-3">Cancel Lend</button>
          </div>
        : <></>}
        {(item.status === "Borrowed") ?
          <div>
            {BorrowStatus()}
            <h3>Item Borrowed</h3>
          </div>
        : <></>}
        {(item.status === "Return Pending") ?
          <div>
            {BorrowStatus()}
            <h3 className="text-middle">Return Pending</h3>
            <hr />
            <button onClick={() => CancelDeclineOrReturn("Returned")} className="btn btn-success float-right mb-3 mr-3">Item Returned</button>
          </div>
        : <></>}
        {((item.status === "Available") || (item.status === "Not Available")) ?
          <div>
            <hr />
            <button onClick={deleteItemButtonRequest} className="btn btn-success float-left mb-3 mr-3">Delete Item</button>
            <NavLink to={{pathname: '/change_item'}} state={{ item: item}} className="btn btn-success float-right mb-3 mr-3">Edit Item</NavLink>
          </div>
        : <></>}
      </div>
      )
    }
    else if(userProfile.userProfile.id === item.borrower.id)
    {
      return (
        <div className="pl-3">
        {(item.status === "Borrow Requested") ?
          <div>
            {BorrowStatus()}
            <h3>Request Pending</h3>
            <hr />
            <button onClick={() => CancelDeclineOrReturn("Cancel")} className="btn btn-danger float-right mb-3 mr-3">Cancel Borrow</button>
          </div>
        : <></>}
        {(item.status === "Borrow Accepted") ?
          <div>
            {BorrowStatus()}
            <h3>Borrow Accepted: Coordinate Item Pick-up/Delivery</h3>
            <hr />
            <button onClick={() => CancelDeclineOrReturn("Cancel")} className="btn btn-danger float-right mb-3 mr-3">Cancel Borrow</button>
          </div>
        : <></>}
        {(item.status === "Borrowed") ?
          <div>
            {BorrowStatus()}
            <h3 className="text-middle">You are Currently Borrowing Item</h3>
            <hr />
            <button onClick={ReturnItem} className="btn btn-success float-right mb-3 mr-3">Return Item</button>
          </div>
        : <></>}
        {(item.status === "Return Pending") ?
          <div>
            {BorrowStatus()}
            <h3>Return Pending</h3>
          </div>
        : <></>}
      </div>
      )
    }
    else {
      return (<div></div>)
    }
  }

  return (
    <div>
    {(userProfile.userProfile.id === item.owner.id) ?
      <div>
        {BorrowWorkflowHeading()}
      </div>
      :
      <div>
        {item.borrower ? BorrowWorkflowHeading() 
        : 
        <div>
        <button type="button" onClick={toggleShowBorrowRequestModal} className="btn btn-success float-right mb-3 mr-3" data-toggle="modal" data-target="#borrowRequestModal">Request to Borrow</button>
        <Modal show={showBorrowRequestModal} onHide={toggleShowBorrowRequestModal} >
          <Modal.Header closeButton>
            <Modal.Title>Request to Borrow</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form">
              <div className="form-row text-left pb-2">
                <div className="col">
                  <label htmlFor="borrowDate"><span style={{color: 'red'}}>*</span> Borrow Date:</label>
                </div>
                <div className="col">
                  <input 
                    type="date" 
                    id="borrowDate"
                    onChange={(e) => {setBorrowDate(e.target.value)}}
                    onBlur={(e) => {validateDates()}}
                    className="form-control"
                    value={borrowDate}
                    min={currentDateString}
                    max={maxDateString}
                    required
                  />
                </div>
              </div>
              <div className="form-row text-left pb-2">
                <div className="col">
                  <label htmlFor="returnDate"><span style={{color: 'red'}}>*</span> Return Date:</label>
                </div>
                <div className="col">
                  <input 
                    type="date" 
                    id="returnDate"
                    onChange={(e) => {setReturnDate(e.target.value);}}
                    onBlur={(e) => {validateDates()}}
                    className="form-control"
                    value={returnDate}
                    min={borrowDate}
                    max={maxDateString}
                    required
                  />
                </div>
              </div>
              <div className="form-row text-left pb-3">
                <div className="col">Cost:</div>
                <div className="col"><i className="fas fa-coins mr-1"></i>{costEstimate}</div>
              </div>
              <div className="form-row text-left pb-2">
                <label htmlFor="selectExchangeMethod" className="col"><span style={{color: 'red'}}>*</span> Method of Exchange:</label>
                <select 
                  onChange={(event)=>{setExchangeMethod(event.target.value)}}
                  className="col custom-select"
                  id="selectExchangeMethod"
                  value={exchangeMethod}
                  required>
                    <option>Borrower Pick Up</option>
                    <option>Owner Drop Off</option>
                    <option>Meet Up</option>
                </select>
              </div>
              <div className="form-row text-left mb-2">
                <label className="col">Comments:</label>
                <textarea
                onChange={(event)=>{setRequestComments(event.target.value)}} 
                className="form-control"
                id="requestCommentsBox" 
                rows={3}
                value={requestComments} 
                placeholder="Comments for owner" 
                >
                </textarea>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleShowBorrowRequestModal}>Close</button>
            <button type="button" className="btn btn-success" onClick={RequestToBorrow}>Request</button>
          </Modal.Footer>
        </Modal>
        </div>}
      </div>
    }
    </div>
  )
}