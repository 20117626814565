import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { resetPasswordConfirm } from '../api/backend-api'


export default function PasswordResetConfirm() {
  const [verifySuccess, setVerifySuccess] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [password2, setPassword2] = useState<string>("")
  const [doPasswordsMatch, setDoPasswordsMatch] = useState<Boolean>(true);
  const [formError, setFormError] = useState<string>("")
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)

  const { uid, token } = useParams()

  const onNewPasswordFormSubmit = (event: any) => {
    event.preventDefault();
    setDisableSubmit(true);
    if (password !== password2){
      setDoPasswordsMatch(false);
      setDisableSubmit(false);
      return
    }
    setDoPasswordsMatch(true)
    if(uid && token) {
      resetPasswordConfirm(uid.toString(), token.toString(), password)
      .then((data: any)=>{
        setVerifySuccess(true)
      })
      .catch((error: any)=> {
        let errString:string = "";
        Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
        //Now capitalize this
        errString = errString.charAt(0).toUpperCase() + errString.slice(1);
        setFormError(errString)
        setDisableSubmit(false);
      })
    }
    

  }
  return (
    <>
    {verifySuccess ?
    <div>
      <h2 className="my-3">Password Reset!</h2>
      <p>Return to <a href="/login">Login</a> page to login with new password.</p>
    </div>
    :
    <div className="padding_section container">
    <div className="row justify-content-center">
      <div className="col-md-5">
        <div className="card text-left">
          <div className="card-body">
            <h1 className="text-start">Reset Password</h1>
            <form onSubmit={onNewPasswordFormSubmit} method="post" autoComplete='off'>
              <div className="form-group text-left">
                <label htmlFor="password">New Password:</label>
                <input 
                  type="password"
                  onChange={(event)=>{setPassword(event.target.value)}}
                  className="form-control" 
                  id="password" 
                  placeholder="Password" 
                  required
                />
              </div>
              {!doPasswordsMatch && <div><label className="form-error">Passwords don't match!</label></div>}
              <div className="form-group text-left">
                <label htmlFor="password">Retype Password:</label>
                <input 
                  type="password"
                  onChange={(event)=>{setPassword2(event.target.value)}}
                  className="form-control" 
                  id="password2" 
                  placeholder="Password" 
                  required
                />
              </div>
              <button type="submit" className="btn btn-success btn-block my-2" disabled={disableSubmit}>Submit</button>
              <label className="form-error">{formError}</label>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
    }
    </>
  )
}