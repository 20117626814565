import React, { useState, useContext, useEffect } from 'react'
import { NavLink } from "react-router-dom"
import Carousel from 'react-bootstrap/Carousel'
import Pagination from './pagination'

import { sendDeletePost, addComment } from '../api/backend-api'
import { UserContext } from '../contexts/userContext';
import UserProfileLink from './user-profile-link'
import { ProjectPost, ProjectPostList, CommentInfo } from '../interfaces/common-types'

import './card-collection.css'

const MAX_COMMENT_LENGTH = 250;

export default function ProjectsPostsCardCollection({
  posts,
  refresh
}: {
  posts: ProjectPostList,
  refresh: () => void
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage] = useState(5)
  const {userProfile} = useContext(UserContext)
  const [comments, setComments] = useState<CommentInfo[]>([])

  const numberOfProjects = posts.posts.length
  const numberOfPages = Math.ceil(numberOfProjects / recordsPerPage)
  const indexOfLastRecord = currentPage * recordsPerPage
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage  
  const currentRecords = posts.posts.slice(indexOfFirstRecord, indexOfLastRecord)

  console.log("Num Posts", numberOfProjects)

  useEffect(() => {
    const initialComments: CommentInfo[] = []

    for (let i = 0; i < posts.posts.length; i++) {
      initialComments.push({comment_text: "", comment_button_disabled: true})
    }

    console.log("initial comments", initialComments)
    setComments(initialComments)
  },[posts.posts.length])

  const updateCommentText = (index: number, text: string) => {
    const updatedComments = [...comments]
    updatedComments[index].comment_text = text
    if(text.length > 0) {
      updatedComments[index].comment_button_disabled = false
    }
    else {
      updatedComments[index].comment_button_disabled = true
    }
    console.log("updated comment", updatedComments[index].comment_text, updatedComments[index].comment_button_disabled)

    setComments(updatedComments) 
  }

  const onCommentFormSubmit = (event: any, postId: number, idx: number) => {
    event.preventDefault()
    
    addComment(postId, comments[idx].comment_text)

    const tempComments = [...comments]
    tempComments[idx].comment_text = ""
    tempComments[idx].comment_button_disabled = true
    setComments(tempComments) 
    refresh()
  }

  const deletePost = (postId: number) => {
    sendDeletePost(postId)
      .then((response) => {
        // console.log("delete post response", response.data)
        refresh();
      })
      .catch((error) => console.log("Posts feed Posts error: ", error.response.status))
    
  }

  const isPostCreator = (creatorId: number) => {
    return creatorId === userProfile.id
  }

  const normalCard = (post: ProjectPost, idx: number) => {
    return(
      <div className="card-body p-0">
        {post.project ?
        <div className="card-header">
          <NavLink to={{pathname: '/project_details'}} state={{ projectId: post.project.id}} className="text-dark text-decoration-none">
            <div className="row">
              <h5 className="col font-weight-bold text-dark text-left align-bottom m-0">{post.project.name}</h5>
            </div>
          </NavLink>
        </div>
        : <></>
        }
        <div className="row">
          <div className="col text-left word-break card-text ml-3">
            <table className="table table-sm table-borderless font-weight-bold">
              <tbody>
                <tr>
                  <td>
                    <div className="row">
                      <div className="col-10"><UserProfileLink userProfileInfo={post.post_creator}></UserProfileLink></div>
                      {isPostCreator(post.post_creator.id)  ?
                      <div className="col-2">
                        <div className="dropdown float-right">
                          <button className="btn btn-light btn-sm rounded-circle m-0" type="button" data-toggle="dropdown" aria-expanded="false">
                            <i className="fas fa-ellipsis-h"></i>
                          </button>
                          <div className="dropdown-menu">
                            {/* <button className="dropdown-item" type="button">Edit</button> */}
                            <button className="dropdown-item" type="button" onClick={() => deletePost(post.id)}>Delete</button>
                          </div>
                        </div>
                      </div>
                      :
                      <></>
                      }
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-left">{post.text}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        { post.images.length > 0 ?
          <div className="row">
            <div className="col mh-100 post-image d-flex justify-content-center">
            <Carousel variant={'dark'} slide={false} interval={null}>
              {post.images.map((image, i) => (
                <Carousel.Item key={i}>
                  <img className="post-card-image" src={image.image} alt={image.id.toString()}/>
                </Carousel.Item>
              ))
              }
            </Carousel>
            </div>
          </div>
          : <></>
        }
        <hr />
        { post.comments && post.comments.length > 0 ?
          <div className="row m-1">
            {post.comments.map((comment, i) => (
              <div className="d-flex">
                <div className="m-1 align-top">
                  <NavLink to={{pathname: '/profile'}} state={{ id: comment.commenter.id}} className="text-decoration-none">
                    <img className="profile-icon rounded-circle text-dark" src={comment.commenter.profile_pic} alt="profile pic"></img> 
                  </NavLink>
                </div>
                <div className="flex-grow-1 text-left">
                  <NavLink to={{pathname: '/profile'}} state={{ id: comment.commenter.id}} className="text-decoration-none">
                    <div className="text-muted">{ comment.commenter.first_name } {comment.commenter.last_name}</div>
                  </NavLink>
                  <p>{comment.text}</p>
                </div>
              </div>
            ))}
            </div>
          : <></>
        }
        <form onSubmit={(e) => onCommentFormSubmit(e, post.id, idx)} method="post">
          <div className="form-group">
            <div className="row m-1">
              <div className="d-flex">
                <div className="m-1 align-self-center">
                  <img className="profile-icon rounded-circle text-dark " src={userProfile.profile_pic} alt="profile pic"></img>
                </div>
                <div className="flex-grow-1">
                  <input 
                    type="text" 
                    onChange={(event)=>{updateCommentText(idx, event.target.value.slice(0,MAX_COMMENT_LENGTH))}} 
                    className="form-control" id="itemName" aria-describedby="nameHelp"
                    value={comments[idx].comment_text}
                    placeholder="Add a comment" 
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row m-1">
              <div className='d-flex justify-content-end'>
                <button type="submit" 
                  className="btn btn-success" 
                  disabled={comments[idx].comment_button_disabled} 
                  hidden={comments[idx].comment_button_disabled}>
                    Comment
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  if (comments.length !== posts.posts.length) {
    return <></>
  }
  else  {
    return (
      <div className="py-2">
        <Pagination
          nPages={numberOfPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        {currentRecords.length > 0 ? currentRecords.map((post: ProjectPost, index: number) => (
          <div className="card post-card bg-white shadow mb-3 rounded" key={post.id}>
            {normalCard(post, index)}
          </div>
        )): 
        <h4 className="text-left">No posts yet</h4> }
        <Pagination
          nPages={numberOfPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div> 
    )
  }
}