import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../contexts/userContext';
import { sendAddPost, sendAddProjectPost, getPostImages, sendDeletePostImage, getUserProjects } from '../api/backend-api';
import { useNavigate, useLocation } from 'react-router-dom';
import { ProjectPost, PostImage, defaultProjectPost, Project } from '../interfaces/common-types';
import axios from 'axios';

import './error.css'
import './item-details.css'

const MAX_TEXT_LENGTH = 500;


export default function AddPost({
  newPost,
  projectId,
  modal,
  closeModal
}:{
  newPost: boolean,
  projectId: number,
  modal: boolean,
  closeModal: () => void
}) {
  const location = useLocation();
  const { state } = location;
  const userProfile = useContext(UserContext)
  const postDetails = newPost ? defaultProjectPost : state.post as ProjectPost
  const [projId, setProjId] = useState<string>(projectId.toString())
  const [projectList, setProjectList] = useState<Project[]>()
  const [postText, setPostText] = useState(newPost ? "" : postDetails.text);
  // const [postId] = useState(newPost ? 0 : postDetails.id)
  const [postImages, setPostImages] = useState<PostImage[]>(postDetails.images)
  const [refreshImages, setRefreshImages] = useState<Boolean>(false)
  const [tempImages, setTempImages] = useState<File[]>();
  const [formError, setFormError] = useState<string>("")
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const navigateToRoot = useNavigate()

  const toggleRefreshImage = () => {
    setRefreshImages(!refreshImages)
  }
  useEffect(() => {
    if(!newPost) {
    getPostImages(postDetails.id)
    .then((response) => {
      // console.log("Post Images Response", response)
      const updatedImages: PostImage[] = response.data.images
      setPostImages(updatedImages)
    })
    .catch((error) => console.log("Posts feed Posts error: ", error.response.status))
  }
  else {
    getUserProjects(userProfile.userProfile.id)
    .then((response) => {
      // console.log("List of projects")
      const userProjects: Project[] = response.data
      setProjectList(userProjects)
    })
    .catch((error) => console.log("Get Projects error", error.response.status))
  }
  }, [refreshImages])

  const onAddPostFormSubmit = (event: any) => {
    event.preventDefault();
    setDisableSubmit(true)

    // console.log("form valid")

    if(projId === "0") {
      if(newPost && tempImages) {
        sendAddPost(postText, tempImages)
          .then((data: any)=>{
            // console.log("addPost Request data: ", data)
            if(data.status === axios.HttpStatusCode.Created){
              if(modal) {
                closeModal()
              }
              else {
                navigateToRoot('/')
              }
            }
            else{
              console.log("Error:", data.status)
            }
          })
          .catch((error: any)=> {
            let errString:string = "";
            Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
              //Now capitable this
            errString = errString.charAt(0).toUpperCase() + errString.slice(1);
            setFormError(errString)
            // console.log("Form Error: ", formError)
            setDisableSubmit(false)
          })
      }
      else if(newPost) {
        sendAddPost(postText)
        .then((data: any)=>{
          // console.log("addPost Request data: ", data)
          if(data.status === axios.HttpStatusCode.Created){
            if(modal) {
              closeModal()
            }
            else {
              navigateToRoot('/')
            }
          }
          else{
            console.log("Error:", data.status)
          }
        })
        .catch((error: any)=> {
          let errString:string = "";
          Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]} \n`});
            //Now capitable this
          errString = errString.charAt(0).toUpperCase() + errString.slice(1);
          setFormError(errString) 
          // console.log("Form Error: ", formError)
          setDisableSubmit(false)
        })
      }
    }
    else {
      if(newPost && tempImages) {
        sendAddProjectPost(projId, postText, tempImages)
          .then((data: any)=>{
            // console.log("addPost Request data: ", data)
            if(data.status === axios.HttpStatusCode.Created){
              if(modal) {
                closeModal()
              }
              else {
                navigateToRoot('/project_details', {state: { projectId: projId}})
              }
            }
            else{
              console.log("Error:", data.status)
            }
          })
          .catch((error: any)=> {
            let errString:string = "";
            Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
            //Now capitable this
            errString = errString.charAt(0).toUpperCase() + errString.slice(1);
            setFormError(errString)
            // console.log("Form Error: ", formError)
            setDisableSubmit(false)
          })
      }
      else if(newPost) {
        sendAddProjectPost(projId, postText)
        .then((data: any)=>{
          // console.log("addPost Request data: ", data)
          if(data.status === axios.HttpStatusCode.Created){
            if(modal) {
              closeModal()
            }
            else {
              navigateToRoot('/project_details', {state: { projectId: Number(projId)}})
            }
          }
          else{
            console.log("Error:", data.status)
          }
        })
        .catch((error: any)=> {
          let errString:string = "";
          Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
          //Now capitable this
          errString = errString.charAt(0).toUpperCase() + errString.slice(1);
          setFormError(errString)
          // console.log("Form Error: ", formError)
          setDisableSubmit(false)
        })
      }
  }
    // else if(!newPost) {
    //   sendUpdatePost(postId, postText, tempImages)
    //     .then((data: any)=>{
    //       console.log("Update Post Request data: ", data)
    //       if(data.status === axios.HttpStatusCode.Ok){
    //         if(modal) {
    //           closeModal()
    //         }
    //       }
    //       else{
    //         console.log("Error:", data.status)
    //       }
    //     })
    //     .catch((error: any)=> {
    //       console.log("Login Error: ", error.response.data)
    //       let errString:string = "";
    //       Object.keys(error.response.data).forEach((key, value) => {errString = errString + `${key} error: ${error.response.data[key]}\n`});
    //       //Now capitable this
    //       errString = errString.charAt(0).toUpperCase() + errString.slice(1);
    //       setFormError(errString)
    //       console.log("Form Error: ", formError)
    //       setDisableSubmit(false)
    //     })
    // }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files
    if(fileList) {
      const fileArray = Array.from(fileList) as File[]
      // console.log("File Array:", fileArray)
      setTempImages(fileArray)
    }
    return
  }

  const deleteImage = (image: PostImage) => {
    // console.log("Post number: ", image.id, "Post url: ", image.image)
    sendDeletePostImage(image.id)
      .then((data: any)=>{
        // console.log("Delete post image: ", data)
        toggleRefreshImage();
      })
      .catch((error: any)=> {
        console.log("Delete post Error: ", error)
      })
  }

  const deleteFile = (arrayIndex: number) => {
    // console.log("starting array ", tempImages)
      if(tempImages && tempImages.length > 0) {
        if(tempImages.length === 1) {
          setTempImages(undefined)
        }
        else if(arrayIndex === tempImages.length) {
          const newImagesArray = tempImages.slice(0, arrayIndex-1)
          setTempImages(newImagesArray)
          // console.log("ending array ", newImagesArray)
        } 
        else if(arrayIndex === 0) {
          const newImagesArray = tempImages.slice(arrayIndex+1, tempImages.length)
          setTempImages(newImagesArray)
          // console.log("ending array ", newImagesArray)
        }
        else if(arrayIndex < tempImages.length) {
          const newImagesLowerArray = tempImages.slice(0, arrayIndex)
          const newImagesArray = newImagesLowerArray.concat(tempImages.slice(arrayIndex+1, tempImages.length))
          setTempImages(newImagesArray)
          // console.log("ending array ", newImagesArray)
        }
    }
  }

  const findProjectName = (projId: number) => {
    return projectList?.find((project: Project) => 
              project.id === projId)
  }

  return (
    <div>
      <h3 className="text-left">{newPost ? "Add Post" : "Update Post"}</h3>
      <form onSubmit={onAddPostFormSubmit} method="post">
        <div className="form-group text-left">
          <label htmlFor="projectSelect" className="font-weight-bold"><span style={{color: 'red'}}>*</span>Post Project</label>
          <select onChange={(event)=>{setProjId(event.target.value)}} className="custom-select" id="projectSelect" defaultValue={projectId} required>
            {projectId === 0 ?
              <option key={0} value={0}>No Project</option>
              :
              <></>
            }
            {projectId === 0 ?
              projectList?.map((project: Project) => 
                <option key={project.id.toString()} value={project.id}>{project.name}</option>)
              :
              <option key={projId} value={projId}>{findProjectName(projectId)?.name}</option>
            }
          </select>
        </div>
        <div className="form-group text-left">
          <label htmlFor="postText" className="font-weight-bold"><span style={{color: 'red'}}>*</span> Post</label>
          <textarea 
            onChange={(event)=>{setPostText(event.target.value.slice(0, MAX_TEXT_LENGTH))}} 
            className="form-control" 
            id="postText" 
            rows={3} 
            aria-describedby="textHelp"
            value={postText} 
            placeholder="Post Text" 
            required
          ></textarea>
          <small id="descriptionHelp" className="form-text text-muted ml-1">Post text. {postText.length}/{MAX_TEXT_LENGTH} characters</small>
        </div>
        <div className="form-row text-left">
          {postImages.map((image, i) => (
              <div key={"storedImage" + i}>
                <img className="my-2 item-image-thumbnail" src={image.image} alt={image.image} />
                <div className="delete-img">
                  <button className="btn btn-light" onClick={() => deleteImage(image)} type="button"><i className="fas fa-trash-alt"></i></button>
                </div>
              </div>
            ))
          }
          {tempImages && tempImages.map((image, i) => (
              <div key={"tempImage" + i}>
                <img className="my-2 item-image-thumbnail" src={URL.createObjectURL(image)} alt={image.name} />
                <div className="delete-img">
                  <button className="btn btn-light" onClick={() => deleteFile(i)} type="button"><i className="fas fa-trash-alt"></i></button>
                </div>
              </div>
            ))
          }
        </div>
        <div className="form-group text-left">
          <label htmlFor="postImage" className="font-weight-bold">Upload Image</label>
          <input type="file" 
            multiple
            className="form-control-file" 
            id="postImage" 
            accept="image/png, image/jpeg, image/jpg" 
            onChange={handleFileChange}
            />
        </div>
        
        <button type="submit" className="btn btn-primary btn-lg btn-block" disabled={disableSubmit}>Add Post</button>
        <label className="form-error">{formError}</label>
      </form>
    </div>
  )
}