import { useState, useEffect, useContext } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import {Tabs, Tab } from 'react-bootstrap'

import AddUser from './add-user'
import UserProfileLink from './user-profile-link'
import CardCollection from './collection-items'
import { communityItemsRequest, communityMemberListRequest, addCommunityMemberRequest, removeCommunityMemberRequest } from '../api/backend-api'
import { Group, ItemList, UserList, UserProfileLite } from '../interfaces/common-types'
import { SearchItems } from '../utils/search'
import { UserContext } from '../contexts/userContext';

import './community.css'

export default function Community({
  searchText
}: {
  searchText: string
}) {
  const location = useLocation();
  const { state } = location;
  const communityDetails: Group = state.communityDetails;
  const userProfile = useContext(UserContext);

  const [items, setItems] = useState<ItemList>();
  const [members, setMembers] = useState<UserList>();
  const [key, setKey] = useState<string>('items');

  const communityTabSelected = (tabSelected: string) => {
    setKey(tabSelected)
    if(tabSelected === "items") {
      getCommunityItems();
    }
    else if(tabSelected === "members") {
      getCommunityMembers();
    }
    else if(tabSelected === "info") {
      getCommunityInfo();
    }
  }

  const onAddUserFormSubmit = (selectedUser: number) => {
    
    if(selectedUser) {
      console.log("Adding User", selectedUser, "To group", communityDetails.id)
      addCommunityMemberRequest(communityDetails.id, selectedUser)
        .then((response) => {
          getCommunityMembers();
        })
        .catch((error) => console.log("getting community items list error: ", error.response.status))
    }
  }

  const getCommunityItems = () => {
    
    communityItemsRequest(communityDetails.id)
      .then((response) => {
        // console.log("getting community items list response: ", response.status)
        const itemsFeed: ItemList = { items: response.data }
        setItems(itemsFeed)
      })
      .catch((error) => console.log("getting community items list error: ", error.response.status))
  }

  useEffect(() => {
    console.log("one shot community items list")
    communityItemsRequest(communityDetails.id)
      .then((response) => {
        // console.log("getting community items list response: ", response.status)
        const itemsFeed: ItemList = { items: response.data }
        setItems(itemsFeed)
      })
      .catch((error) => console.log("getting community items list error: ", error.response.status))
  }, [communityDetails])

  const getCommunityMembers = () => {
    // console.log("getting community members")
    communityMemberListRequest(communityDetails.id)
      .then((response) => {
        // console.log("getting community members response: ", response.status)
        const memberList: UserList = { users: response.data }
        setMembers(memberList)
      })
      .catch((error) => console.log("getting community members error: ", error.response.status))
  }

  const removeCommunityMember = (memberId: number) => {
    removeCommunityMemberRequest( communityDetails.id, memberId)
      .then((response) => {
        // console.log("remove community member response: ", response.status);
        getCommunityMembers();
      })
      .catch((error) => console.log("remove community member error: ", error.response.status))
  }

  const getCommunityInfo = () => {
    // console.log("getting community info")
  }

  const communityItems = () => {
    if(items)
    {
      if(key === "items") {
        return (
          <div>
            {items.items.length > 0 ? 
              <CardCollection 
                items={SearchItems(items, searchText)}
                searchText={searchText}
                homeFeed={true}
              />
            :
            <h4 className="text-left">No Community Items</h4>
            }
          </div>
        )
      }
    }
    else {
      return (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )
    }
  }

  const communityMembers = () => {
    if(members) {
      console.log("list community members")
      return (
      <div className="card bg-light" style={{maxHeight: 'none'}}>
        <ul className="list-group list-group-flush">
        {members.users.length > 0 ? members.users.map((member: UserProfileLite) => (
            <li className="list-group-item" key={member.id}>
              <div className="float-left">
                <UserProfileLink userProfileInfo={member}></UserProfileLink>
              </div>
              { communityDetails.group_owner.id === userProfile.userProfile.id ? 
              <div className="float-right" onClick={() => removeCommunityMember(member.id)}>
                <button className="btn btn-light">Remove</button>
              </div>
               : <div></div> }
            </li>
        ))
        : 
        <h4 className="text-left">Community has no members</h4> 
        }
        </ul>
        { communityDetails.group_owner.id === userProfile.userProfile.id ? 
        <AddUser buttonName={"Add Member"} addUserFormSubmit={onAddUserFormSubmit} friendsOnly={false}/>
        : <div></div> }
      </div>
      )
    }
    else {
      return (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )
    }
  }

  const communityInfo = () => {
    return (
    <div className="card bg-light">
        <div className="row pl-3">
          <div className="col-sm-8 text-left text-nowrap">
            <table className="table table-sm table-borderless font-weight-bold table-light">
              <tbody>
                <tr>
                  <th scope="row" className="align-middle">Group Owner:</th>
                  <td className="text-left align-middle"><UserProfileLink userProfileInfo={communityDetails.group_owner}></UserProfileLink></td>
                </tr>
                <tr>
                  <th scope="row" className="align-middle">Description:</th>
                  <td className="text-left align-middle">{communityDetails.description}</td>
                </tr>
                { userProfile.userProfile.is_staff ? 
                <tr>
                  <th scope="row" className="align-middle">Group ID:</th>
                  <td className="text-left align-middle">{communityDetails.id}</td>
                </tr>
                : <></> }
              </tbody>
            </table>
          </div>
          { communityDetails.group_owner.id === userProfile.userProfile.id ? 
          <div className="row">
            <div className="float-right">
              <NavLink to={{pathname: '/add_modify_group'}} state={{community: communityDetails}} className="btn btn-success float-right m-1" >Edit Group Info</NavLink>
            </div>
          </div>
          : <div></div> }
        </div>
    </div>
    )
  }

  return (
    <div>
      <h3 className="text-left ml-1">Communities -{'>'} {communityDetails.group_name}</h3>
      <Tabs 
        className="font-weight-bold" 
        activeKey={key}
        onSelect={(k) => {if(k) {communityTabSelected(k)}}}
        fill
      >
        <Tab eventKey="items" title="Items">{communityItems()}</Tab>
        <Tab eventKey="members" title="Members">{communityMembers()}</Tab>
        <Tab eventKey="info" title="Info">{communityInfo()}</Tab>
      </Tabs>
    </div>
  )
}