import React, {useContext} from 'react'
import { useParams } from 'react-router-dom'
import {Tabs, Tab } from 'react-bootstrap'
import { UserContext } from '../contexts/userContext';
import ProjectPostsFeed from './projects-feed'
import UserProjects from './projects-user-projects'

export default function Projects({
  searchText
}: {
  searchText: string
}) {

  const {active_tab} = useParams();
  const userProfile = useContext(UserContext)

  return (
    <div>
    <h3 className="text-left ml-1">Projects</h3>
    <Tabs 
      className="font-weight-bold" 
      defaultActiveKey={active_tab}
      fill
    >
      <Tab eventKey="feed" title="Feed" role={"presentation"}><ProjectPostsFeed searchText={searchText}/></Tab>
      <Tab eventKey="user_projects" title="My Projects" role={"presentation"}><UserProjects userId={userProfile.userProfile.id} searchText={searchText}/></Tab>
    </Tabs>
  </div>
  )
}
