import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'

import { searchForUser, searchForFriend } from '../api/backend-api'
import { UserList, UserProfileLite } from '../interfaces/common-types'

export default function AddUser({
  buttonName,
  friendsOnly,
  addUserFormSubmit
}:{
  buttonName: string,
  friendsOnly: boolean,
  addUserFormSubmit: (user: number) => void
}) {
  const [userSearch, setUserSearch] = useState<string>("");
  const [returnedUsers, setReturnedUsers] = useState<UserList>({users: []});
  const [selectedUser, setSelectedUser] = useState<number>();
  const [addUserModal, setAddUserModel] = useState<boolean>(false);

  const toggleAddUserModel = () => {
    setAddUserModel(!addUserModal);
  }

  const addUser = () => {
    if(selectedUser)
    {
      addUserFormSubmit(selectedUser)
    }
    toggleAddUserModel();
    setSelectedUser(undefined);
    setUserSearch("");
  }

  const userSelection = (selectedMemberId: number) => {
    if(selectedMemberId === selectedUser) {
      setSelectedUser(undefined);
    }
    else {
      setSelectedUser(selectedMemberId)
    }
  }

  useEffect(() => {
    if(userSearch !== "") {
      if(friendsOnly) {
    searchForFriend(userSearch)
      .then((response) => {
        console.log("search for friend response: ", response.status)
        const returnedUserList: UserList = { users: response.data }
        setReturnedUsers(returnedUserList)
      })
      .catch((error) => {
        console.log("getting community items list error: ", error.response.status)
        setReturnedUsers({users: []})
      })
    }
    else {
      searchForUser(userSearch)
      .then((response) => {
        console.log("search for users response: ", response.status)
        const returnedUserList: UserList = { users: response.data }
        setReturnedUsers(returnedUserList)
      })
      .catch((error) => {
        console.log("getting community items list error: ", error.response.status)
        setReturnedUsers({users: []})
      }) 
    }
    }
  }, [userSearch])

  return (
    <>
      <button className="btn btn-success w-10 align-self-end m-1" onClick={toggleAddUserModel} data-toggle="modal" data-target="#addUserModal">{buttonName}</button>
      <Modal className="modal-dialog-scrollable" show={addUserModal} onHide={toggleAddUserModel} >
      <Modal.Header closeButton>
        <Modal.Title>Search for User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form className="form">
        <div className="form-group text-left">
          <input 
            type="text" 
            onChange={(event)=>{setUserSearch(event.target.value)}} 
            className="form-control" id="searchText" aria-describedby="searchText"
            value={userSearch}
            placeholder="User's name" 
            required
          />
        </div>
      </form>
      <ul className="list-group list-group-flush list-limit">
      {returnedUsers.users.length > 0 ? returnedUsers.users.map((member: UserProfileLite) => (
        <li
          className={(selectedUser === member.id) ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}
          onClick={() => userSelection(member.id)}
          key={member.id}
          >
            <img className="profile-icon rounded-circle text-dark" src={member.profile_pic} alt="profile pic"></img> { member.first_name } {member.last_name}
        </li>
      )): <></>}
      </ul>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={toggleAddUserModel}>Cancel</button>
        <button type="button" className={selectedUser ? "btn btn-success": "btn btn-success disabled"} onClick={addUser}>Start Chat</button>
      </Modal.Footer>
    </Modal>
  </>
  )
}