// Constants.tsx

const prod = {
  BACKEND_URL: "https://partak-backend.onrender.com",
  STREAM_KEY: "agcdj29kyq84"
}

const dev = {
  BACKEND_URL: "http://localhost:8000",
  STREAM_KEY: "8tg52nxx68x5"
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod; 