import React, { useState, useContext } from 'react'
import { sendCreateGroup, updateGroupDetails } from '../api/backend-api';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/userContext';
import { Group } from '../interfaces/common-types'

import axios from 'axios'; 

export default function AddModifyGroup() {
  const userProfile = useContext(UserContext)
  const location = useLocation()
  const { state } = location
  const communityDetails: Group = state.community
  const isExistingCommunity = () => {
    return (state.newGroup !== true)
  }
  const [groupName, setGroupName] = useState<string>(communityDetails ? communityDetails.group_name: "");
  const [groupDescription, setGroupDescription] = useState<string>(communityDetails ? communityDetails.description : "")
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)

  const navigateToRoot = useNavigate()

  const onCreateGroupFormSubmit = (event: any) => {
    event.preventDefault();
    setDisableSubmit(true)
    if (!isValidForm()){
      console.log("form invalid")
      setDisableSubmit(false)
      return
    }
      if(isExistingCommunity() && communityDetails) {
        console.log("Existing Community to update")
        updateGroupDetails(communityDetails.id, groupName, groupDescription)
          .then((data: any)=>{
            console.log("Update Community Request data: ", data)
            if(data.status === axios.HttpStatusCode.Ok){
              navigateToRoot('/groups')
            }
          })
          .catch((error: any)=> {
            console.log("Community update erro: ", error)
            setDisableSubmit(false)
          })
      }
      else {
        sendCreateGroup(groupName, userProfile.userProfile.id.toString(), groupDescription)
          .then((data: any)=>{
            console.log("creategroup Request data: ", data)
            if(data.status === axios.HttpStatusCode.Created){
              navigateToRoot('/groups')
            }
          })
          .catch((error: any)=> {
            console.log("Login Error: ", error)
            setDisableSubmit(false)
          })
      }
    }

  const isValidForm = () => {
    // setErrorDisplay("")
    // if (username === "" || password === ""){
    //   setErrorDisplay("username and password can't be empty. try user: test, password: test")
    //   return false;
    // }
    return true;
  }

  return(
    <div>
      <form onSubmit={onCreateGroupFormSubmit} method="post">
        <div className="form-group text-left">
          <label htmlFor="groupName">Name</label>
          <input 
            type="text" 
            onChange={(event)=>{setGroupName(event.target.value)}} 
            className="form-control" id="itemName" aria-describedby="nameHelp"
            value={groupName}
            placeholder="Name of Community" 
            required
          />
        </div>
        <div className="form-group text-left">
          <label htmlFor="groupDescription">Name</label>
          <textarea 
            onChange={(event)=>{setGroupDescription(event.target.value)}} 
            className="form-control" 
            id="groupDescription"
            rows={3}
            value={groupDescription}
            placeholder="Describe the community" 
            required
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary btn-lg btn-block" disabled={disableSubmit}>{isExistingCommunity() ? "Update Group" : "Create Group"}</button>
      </form>
    </div>
  )
}